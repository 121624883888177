import React, { useEffect } from 'react'
import AlertCard from '../../molecules/AlertCard'
import DomainCard from '../../molecules/DomainCard'
import { useSelector, useDispatch } from 'react-redux'
import { fetchDashBoardBarData } from '../../../redux/getDashboardBarDataSlice';

const DashboardBar = () => {
    const dispatch = useDispatch();
    let data = useSelector((state) => state.dashboardBarData?.data);
    
    useEffect(() => {
        dispatch(fetchDashBoardBarData());
    }, []);

  return (
    <div className='my-8 flex gap-4 flex-row p-4 rounded-md bg-gray-100'>
         {data && data.map((product, i) => {
           return product.map((productDetails) => {
            let counts = []
            if(productDetails?.ProductType.toLowerCase()==='bw5'){
              counts = [{
                  label: 'machines',
                  value: productDetails.machineCount || '-',
              },
              {
                label: 'domains',
                value: productDetails.domainCount || '-',
            },
            {
              label:'apps',
              value: productDetails.applicationCount || '-',
          }]
            }else{
              counts = [{
                  label: 'machines',
                  value: productDetails.machineCount || '-',
              },
              {
                label: 'domains',
                value: productDetails.domainCount || '-',
            },
            {
              label:'apps',
              value: productDetails.applicationCount || '-',
          },
          {
            label:'appspaces',
            value: productDetails.AppSpaceCount || '-',
        },
        {
          label:'appnodes',
          value: productDetails.AppNodeCount || '-'
      }]
            }
              return <DomainCard counts={counts} key={i} title={productDetails.ProductType} />
         })})}
        <AlertCard />
        </div> 
  )
}

export default DashboardBar