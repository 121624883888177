import React, { useState, useEffect } from 'react';
import {
  TrendingUpOutlined,
  FlashOnOutlined,
  ApiOutlined,
  CheckCircleOutline,
  SpeedOutlined,
  CloudDoneOutlined,
  RocketLaunchOutlined,
} from '@mui/icons-material';

const MarketingHeader = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const slides = [
    {
      icon: RocketLaunchOutlined,
      title: 'Leave the slow lane behind',
      description: 'Use SHIP accelerator agent to arrive 60% faster',
      stats: [
        { icon: SpeedOutlined, text: '60% faster migration' },
        { icon: CheckCircleOutline, text: 'Kick start your progress' },
        { icon: CloudDoneOutlined, text: 'Cloud-ready code' },
      ],
    },
    {
      icon: TrendingUpOutlined,
      title: '40% more value, 100% less hassle',
      description: 'Work smarter, save bigger – let SHIP™ do the rest',
      stats: [
        { icon: CheckCircleOutline, text: '40% cost reduction' },
        { icon: SpeedOutlined, text: 'Rapid development' },
        { icon: CloudDoneOutlined, text: 'Minimal config changes' },
      ],
    },
    {
      icon: FlashOnOutlined,
      title: 'Break free from vendor lock-ins',
      description: 'Modernize your integration applications effortlessly',
      stats: [
        { icon: CheckCircleOutline, text: 'Platform agnostic' },
        { icon: SpeedOutlined, text: 'Seamless transition' },
        { icon: CloudDoneOutlined, text: 'Future-proof solution' },
      ],
    },
    {
      icon: ApiOutlined,
      title: 'Break the monolith, build the future',
      description: 'Move from monolithic to API-led composable systems',
      stats: [
        { icon: CheckCircleOutline, text: 'Microservices ready' },
        { icon: SpeedOutlined, text: 'API-first approach' },
        { icon: CloudDoneOutlined, text: 'Modern architecture' },
      ],
    },
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % slides.length);
    }, 6000); // Changed to 6 seconds for better readability

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="bg-gradient-to-br from-theme-1 to-theme-2 rounded-lg shadow-lg pb-0">
      <div className="p-4 pb-0">
      <div class="mask is-diamond absolute top-20 right-0 -m-2 size-40 bg-white/20"></div>
        {/* Header Section */}
        <div className="flex items-center justify-between pb-0">
          <div>
            <h1 className="text-2xl font-bold text-white">
              Transform Your Integration Journey with SHIP
            </h1>
            <p className="text-md text-slate-100 mt-1">
              Gen AI powered Boomi migration agent
            </p>
          </div>
          <div className="boxicon -mt-4">
          <RocketLaunchOutlined className="text-slate-100 w-20 h-20 " fontSize="inherit"/>
          </div>
        </div>

        {/* Carousel Container */}
        <div className="relative h-32 mt-0">
          {/* Slides */}
          {slides.map((slide, index) => {
            const Icon = slide.icon;
            return (
              <div
                key={index}
                className={`absolute top-0 left-0 w-full transform transition-all duration-700 ease-out
                  ${
                    index === currentSlide
                      ? 'opacity-100 translate-y-0'
                      : 'opacity-0 translate-y-8'
                  }`}
              >
                <div className="rounded-lg space-y-2">
                  <div className="flex items-center gap-2">
                    <Icon className="text-blue-400" />
                    <h3 className="text-lg font-semibold text-blue-200">
                      {slide.title}
                    </h3>
                  </div>
                  <p className="text-gray-200">{slide.description}</p>

                  {/* Stats Row */}
                  <div className="flex gap-6 mt-2">
                    {slide.stats.map((stat, statIndex) => {
                      const StatIcon = stat.icon;
                      return (
                        <div
                          key={statIndex}
                          className="flex items-center gap-1"
                        >
                          <StatIcon className="text-blue-500 w-4 h-4" />
                          <span className="text-sm text-gray-200">
                            {stat.text}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          })}

          {/* Navigation Dots */}
          {/* <div className="absolute -bottom-4 left-0 right-0 flex justify-center gap-1">
            {slides.map((_, index) => (
              <button
                key={index}
                onClick={() => setCurrentSlide(index)}
                className={`w-1.5 h-1.5 rounded-full transition-all duration-300
                  ${
                    index === currentSlide ? 'bg-blue-600 w-3' : 'bg-blue-300'
                  }`}
                aria-label={`Go to slide ${index + 1}`}
              />
            ))}
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default MarketingHeader;
