import React, { useCallback, useEffect, useState } from "react";
import ReactFlow, {
  addEdge,
  ConnectionLineType,
  Panel,
  useNodesState,
  useEdgesState,
  useReactFlow,
} from "reactflow";
import "reactflow/dist/style.css";
import { IconButton, Tooltip } from "@mui/material";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { removeReactFlowWatermark } from "./utils";
import Popover from "./Popover";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import ZoomInMapIcon from "@mui/icons-material/ZoomInMap";
import Node from "./customNodes/Node";
import BasicNode from "./customNodes/BasicNode";
const nodeTypes = {
  basic: Node,
  basicNode: BasicNode,
};

const Flow = ({ data, toggleModal, open, showExpandIcon = true }) => {
  const { fitView } = useReactFlow();
  const [stepDetails, setStepDetails] = useState(null);
  const [nodes, setNodes, onNodesChange] = useNodesState(data.nodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(data.edges);

  useEffect(() => removeReactFlowWatermark(), []);

  const onConnect = useCallback(
    (params) =>
      setEdges((eds) =>
        addEdge(
          { ...params, type: ConnectionLineType.SmoothStep, animated: true },
          eds
        )
      ),
    []
  );

  const handleReset = () => {
    window.requestAnimationFrame(() => fitView());
  };

  return (
    <>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        nodesDraggable={false}
        connectionLineType={ConnectionLineType.SmoothStep}
        fitView
        nodeTypes={nodeTypes}
        onNodeClick={(evt, node) => {
          setStepDetails({ evt: evt.currentTarget, node });
        }}
      >
        <Panel position="bottom-right">
          <Tooltip title="Reset position" placement="bottom" arrow>
            <IconButton onClick={handleReset}>
              <RestartAltIcon />
            </IconButton>
          </Tooltip>
          {showExpandIcon && (
            <Tooltip
              title={open ? "Collapse" : "Expand"}
              placement="bottom"
              arrow
            >
              <IconButton onClick={toggleModal}>
                {open ? <ZoomInMapIcon /> : <ZoomOutMapIcon />}
              </IconButton>
            </Tooltip>
          )}
        </Panel>
      </ReactFlow>

      {/* <Popover
        anchor={stepDetails?.evt || null}
        onClose={() => setStepDetails(null)}
        nodeData={stepDetails?.node || null}
        onBottom={true}
      /> */}
    </>
  );
};

export default Flow;
