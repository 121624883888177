import React, { useEffect, useState } from "react";
import Logo from "../../../assests/logo.png";
import illustrationUrl from "../../../assests/artificial-intelligence.png";
import AuthService from "../../../services/AuthService";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import { useGet, useNotify } from "../../../shared/hooks";
import { NotificationConstants } from "../../../utils/constants";
const NOTIFICATION_TYPE = NotificationConstants.NOTIFICATION_TYPE;

const authService = new AuthService();


const Login = () => {

  const [notify] = useNotify();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    if (authService.isAuthenticated()) {
      navigate("/");
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { search } = window.location;
    const [_, value] = search?.split("=");
    let token = await authService.login(formData.email, formData.password);
    console.log(token);
    if (token) {
      navigate(search ? value : "/");
    } else {
      notify(true, "Invalid credentials, please try again", NOTIFICATION_TYPE.error, 2000);
    }
  };

  return (
    <>
      <div
        className={clsx([
          "p-3 sm:px-8 relative h-screen lg:overflow-hidden bg-success xl:bg-white dark:bg-darkmode-800 xl:dark:bg-darkmode-600",
          "before:hidden before:xl:block before:content-[''] before:w-[57%] before:-mt-[28%] before:-mb-[16%] before:-ml-[13%] before:absolute before:inset-y-0 before:left-0 before:transform before:rotate-[-4.5deg] before:bg-primary/20 before:rounded-[100%] before:dark:bg-darkmode-400",
          "after:hidden after:xl:block after:content-[''] after:w-[57%] after:-mt-[20%] after:-mb-[13%] after:-ml-[13%] after:absolute after:inset-y-0 after:left-0 after:transform after:rotate-[-4.5deg] after:bg-primary after:rounded-[100%] after:dark:bg-darkmode-700",
        ])}
      >
        <div className="container relative z-10 sm:px-10">
          <div className="block grid-cols-2 gap-4 xl:grid">
            {/* BEGIN: Login Info */}
            <div className="flex-col hidden min-h-screen xl:flex">
              <a href="" className="flex items-center pt-5 -intro-x"></a>
              <div className="my-auto">
                <img
                  alt="AI Illustration"
                  className="w-1/2 -mt-16 -intro-x"
                  src={illustrationUrl}
                />

                <div className="mt-10 text-4xl font-medium leading-tight text-white -intro-x">
                  Work smarter, save bigger <br />
                  let SHIP™ do the rest
                </div>
                <div className="mt-5 text-lg text-white -intro-x text-opacity-70 dark:text-slate-400">
                  Gen AI powered Boomi migration agent
                </div>
              </div>
            </div>
            {/* END: Login Info */}
            {/* BEGIN: Login Form */}
            <div className="flex h-screen py-5 my-10 xl:h-auto xl:py-0 xl:my-0">
              <div className="w-full px-5 py-8 mx-auto my-auto bg-white rounded-md shadow-md xl:ml-20 dark:bg-darkmode-600 xl:bg-transparent sm:px-8 xl:p-0 xl:shadow-none sm:w-3/4 lg:w-2/4 xl:w-auto">
                <div className="flex items-center justify-center">
                  <img className="w-40 mr-2" src={Logo} alt="logo" />
                </div>
                <div className="p-10 mt-0">
                  {/* <h1 className="text-xl pb-4  text-center leading-tight tracking-tight text-[#542344] md:text-2xl dark:text-white">
              Sign in to your account
            </h1> */}
                  <form
                    className="space-y-4 md:space-y-6"
                    onSubmit={handleSubmit}
                  >
                    <div>
                      <label
                        htmlFor="email"
                        className="block mb-1  text-sm text-gray-900 dark:text-white"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="bg-primaryText border-0 border-b-2 border-black text-gray-900 sm:text-sm   focus:border-[#592548] block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-secondary-500 dark:focus:border-secondary-500 outline-0"
                        placeholder="name@company.com"
                        required
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="password"
                        className="block mb-1 text-sm text-gray-900 dark:text-white"
                      >
                        Password
                      </label>
                      <input
                        type="password"
                        name="password"
                        id="password"
                        value={formData.password}
                        onChange={handleChange}
                        placeholder="••••••••"
                        className="bg-primaryText border-0 border-b-2 focus:border-[#592548] border-black text-gray-900 sm:text-sm focus:ring-secondary-600 focus:border-secondary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-secondary-500 dark:focus:border-secondary-500 outline-0"
                        required
                      />
                    </div>
                    <div className="flex items-center justify-between">
                      <div></div>
                      <a
                        href="#"
                        className="text-sm font-medium text-secondary-600 hover:underline dark:text-secondary-500"
                      >
                        Forgot password?
                      </a>
                    </div>
                    <div className="flex flex-row gap-2">
                      <button
                        type="submit"
                        className="w-full text-white bg-primary hover:bg-secondary-700 focus:ring-4 focus:outline-none focus:ring-secondary-300 font-normal rounded-lg text-sm px-5 py-2.5 text-center dark:bg-secondary-600 dark:hover:bg-secondary-700 dark:focus:ring-secondary-800"
                      >
                        Access Migration
                      </button>
                      <button
                        onClick={() => navigate("/d/")}
                        className="w-full text-white bg-primary hover:bg-secondary-700 focus:ring-4 focus:outline-none focus:ring-secondary-300 font-normal rounded-lg text-sm px-5 py-2.5 text-center dark:bg-secondary-600 dark:hover:bg-secondary-700 dark:focus:ring-secondary-800"
                      >
                        Access Discovery
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            {/* END: Login Form */}
          </div>
        </div>
      </div>
    </>
    // <section className="bg-gradient-to-tr from-blue-100 to-green-100 dark:bg-gray-900 font-poppins h-screen w-screen">
    //   <div className="flex flex-col items-center justify-center py-2 mx-auto md:h-screen lg:py-0">
    //     <div className="w-full bg-white rounded-lg shadow-xl dark:border md:mt-0 sm:max-w-md pt-12 dark:bg-gray-800 dark:border-gray-700">
    //       <div className="items-center justify-center grid grid-cols-1">
    //         <div className="mx-auto flex items-center mb-2 text-2xl font-semibold text-gray-900 dark:text-white">
    //           <img className="w-60 mr-2" src={Logo} alt="logo" />
    //           {/* .AI */}
    //         </div>
    //         <div className="mx-auto text-xl ">
    //           <span className="text-primary font-extrabold">S</span>age IT{' '}
    //           <span className="text-primary font-extrabold">H</span>ub for{' '}
    //           <span className="text-primary font-extrabold">I</span>ntegration{' '}
    //           <span className="text-primary font-extrabold">P</span>latforms
    //         </div>
    //       </div>
    //       <div className="p-10 mt-0">
    //         {/* <h1 className="text-xl pb-4  text-center leading-tight tracking-tight text-[#542344] md:text-2xl dark:text-white">
    //           Sign in to your account
    //         </h1> */}
    //         <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
    //           <div>
    //             <label
    //               htmlFor="email"
    //               className="block mb-1  text-sm text-gray-900 dark:text-white"
    //             >
    //               Email
    //             </label>
    //             <input
    //               type="email"
    //               name="email"
    //               id="email"
    //               value={formData.email}
    //               onChange={handleChange}
    //               className="bg-primaryText border-0 border-b-2 border-black text-gray-900 sm:text-sm   focus:border-[#592548] block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-secondary-500 dark:focus:border-secondary-500 outline-0"
    //               placeholder="name@company.com"
    //               required
    //             />
    //           </div>
    //           <div>
    //             <label
    //               htmlFor="password"
    //               className="block mb-1 text-sm text-gray-900 dark:text-white"
    //             >
    //               Password
    //             </label>
    //             <input
    //               type="password"
    //               name="password"
    //               id="password"
    //               value={formData.password}
    //               onChange={handleChange}
    //               placeholder="••••••••"
    //               className="bg-primaryText border-0 border-b-2 focus:border-[#592548] border-black text-gray-900 sm:text-sm focus:ring-secondary-600 focus:border-secondary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-secondary-500 dark:focus:border-secondary-500 outline-0"
    //               required
    //             />
    //           </div>
    //           <div className="flex items-center justify-between">
    //             <div></div>
    //             <a
    //               href="#"
    //               className="text-sm font-medium text-secondary-600 hover:underline dark:text-secondary-500"
    //             >
    //               Forgot password?
    //             </a>
    //           </div>
    //           <div className="flex flex-row gap-2">
    //             <button
    //               type="submit"
    //               className="w-full text-white bg-primary hover:bg-secondary-700 focus:ring-4 focus:outline-none focus:ring-secondary-300 font-normal rounded-lg text-sm px-5 py-2.5 text-center dark:bg-secondary-600 dark:hover:bg-secondary-700 dark:focus:ring-secondary-800"
    //             >
    //               Access Migration
    //             </button>
    //             <button
    //               onClick={() => navigate('/d/')}
    //               className="w-full text-white bg-primary hover:bg-secondary-700 focus:ring-4 focus:outline-none focus:ring-secondary-300 font-normal rounded-lg text-sm px-5 py-2.5 text-center dark:bg-secondary-600 dark:hover:bg-secondary-700 dark:focus:ring-secondary-800"
    //             >
    //               Access Discovery
    //             </button>
    //           </div>
    //         </form>
    //       </div>
    //     </div>
    //   </div>
    // </section>
  );
};

export default Login;
