import React, { useState, useCallback, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import Table from "../../../shared/components/organisms/table/Table";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchAllAlertData } from "../../../shared/redux/getAlertsDataSlice";
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';

const customClasses = {
  table: "flex-row-reverse w-full overflow-y rounded-md",
  thead: "bg-secondary text-center h-12 uppercase text-white sticky top-0 z-10",
  th: "px-2",
  tr: "justify-between flex-grow mb-2",
  td: "bg-white min-w-8 truncate text-ellipsis text-center text-sm py-4 px-6 align-middle border-b border-secondary-100",
};

const Alerts = () => {
  const location = useLocation();
  const pathname = location.pathname;


  const lastSlashIndex = pathname.lastIndexOf("/");
  const applicationName =
    lastSlashIndex !== -1 ? pathname.substring(lastSlashIndex + 1) : null;
  const data = useSelector((state) => state.alertsData?.data);
  const [pageCount, setPageCount] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const columns = React.useMemo(
    () => [
      {
        Header: "Alert Name",
        accessor: "AlertName",
        Cell: ({ row }) => (
          <button
            className="hover:text-primary self"
            onClick={() =>
              navigate(`/d/alerts/${row.original.applicationName}`)
            }
          >
            {row.original.AlertName}
          </button>
        ),
      },
      { Header: "Status", accessor: "AlertStatus", Cell: ({ row }) => row.original.AlertStatus === "firing" ? <div className="flex flex-row gap-2 justify-evenly"><div className="w-3 h-3 rounded-full moving-dot" /><span className="text-red-500">{row.original.AlertStatus}</span></div> : <span className="text-green-500">{row.original.AlertStatus}</span>},
      { Header: "Node Name", accessor: "NodeName" },
      { Header: "Alert Type", accessor: "AlertType" },
      { Header: "Created At", accessor: "AlertCreatedTimestamp" },
      {
        Header: "Detail Url",
        accessor: "AlertDetailURL",
        Cell: ({ row }) => (
          <a target="_blank" 
          href={`${row.original.AlertDetailURL}`}
            className="hover:text-primary self-center"
          >
            <LaunchOutlinedIcon />
          </a>
        ),
      },
      { Header: "Alert Description", accessor: "AlertDescription" },

    ],
    []
  );

    const fetchData = useCallback((pageIndex) => {
    console.log("Async Data CALLED", pageIndex);
    dispatch(fetchAllAlertData(pageIndex));
    setPageCount(pageCount + 1);
  }, []);

  return (
    <div className="flex flex-col py-6">
      {/* Breadcrumb navigation */}
      <div>
        <Link
          to="/d"
          className="text-primary hover:text-primaryLight text-lg font-medium"
        >
          Dashboard
        </Link>
        {applicationName && (
          <span className="ml-2 text-primary text-lg font-medium hover:text-primaryLight">
            &gt; {applicationName.charAt(0).toUpperCase() + applicationName.slice(1)}
          </span>
        )}
      </div>
      <div className="bg-white rounded-lg p-2 shadow-md mt-6">
        <div className="h-full rounded-md">
          <div className="h-full max-h overflow-y-auto">
        {
          <Table
            columns={columns}
            data={data}
            fetchData={fetchData}
            pageCount={pageCount}
            customClasses={customClasses}
          />
        }
      </div>
      </div>
      </div>
    </div>
  );
};

export default Alerts;
