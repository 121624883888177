import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useGet, useNotify, usePost, usePut } from "../../../shared/hooks";
import { ProjectService } from "../../../services";
import { NotificationConstants } from "../../../utils/constants";
import { LoadingButton } from "@mui/lab";
import CustomizationPanel from "./CustomizationPanel";
import { mapCustomization } from "../../../utils";

const projectService = new ProjectService();
const NOTIFICATION_TYPE = NotificationConstants.NOTIFICATION_TYPE;
export const newCustomPair = { key: "", value: "" };

const CreateCampaign = ({ handleClose, onCampaignCreated, id }) => {
  const [notify] = useNotify();
  const [formData, setFormData] = useState({
    name: undefined,
    customizations: { function_mapping: [newCustomPair] },
  });

  const { PutData, loading: creating } = usePut();
  const { postData, loading: updating } = usePost();
  const { fetchData, loading } = useGet();

  const getCampaign = async (id) => {
    const resp = await fetchData(projectService.projectByIdUrl(id));
    let custArr = [];
    try {
      custArr = mapCustomization(resp.customization);
    } catch (e) {
      // customization could be null or error while parsing
    }
    setFormData({ ...resp, customizations: { function_mapping: custArr } });
  };

  const handleChange = (e) => {
    setFormData((pre) => ({ ...pre, name: e.target.value }));
  };

  useEffect(() => {
    if (id) {
      getCampaign(id);
    }
  }, []);

  const handleCreateCampaign = async (projectName, custom) => {
    const formData = new FormData();
    formData.append("user_id", "schitipotu@sageitinc.com");
    formData.append("name", projectName);
    formData.append("customization", JSON.stringify(custom));
    try {
      const resp = await PutData(projectService.createProjectUrl(), formData);
      onCampaignCreated(resp);
      notify(true, "Team created successfully", NOTIFICATION_TYPE.success);
      handleClose();
    } catch (err) {
      notify(true, err.message, NOTIFICATION_TYPE.error, 5000);
    }
  };

  const handleUpdateCampaign = async (name, custom) => {
    const _formData = new FormData();
    _formData.append("user_id", formData.user_id);
    _formData.append("name", name);
    _formData.append("customization", JSON.stringify(custom));
    _formData.append("id", formData.id);
    try {
      const resp = await postData(
        projectService.projectByIdUrl(formData.id),
        _formData
      );
      onCampaignCreated(resp);
      notify(true, "Team updated successfully", NOTIFICATION_TYPE.success);
      handleClose();
    } catch (err) {
      notify(true, err.message, NOTIFICATION_TYPE.error, 5000);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let custom = {};
    Object.values(formData.customizations)[0].forEach(
      (pair) => (custom = { ...custom, [pair.key]: pair.value })
    );
    if (id) {
      handleUpdateCampaign(formData.name, custom);
      return;
    }
    handleCreateCampaign(formData.name, custom);
  };

  return (
    <Dialog
      open={true}
      fullWidth
      maxWidth="md"
      onClose={handleClose}
      PaperProps={{
        component: "form",
        onSubmit: handleSubmit,
      }}
    >
      <DialogTitle>{id ? "Edit" : "Create"} Team</DialogTitle>
      {loading ? (
        <div className="h-80 flex justify-center">
          <CircularProgress className="" />
        </div>
      ) : (
        <DialogContent>
          <DialogContentText className="pb-4">
            To create new Team, please select existing project and provide
            required details.
          </DialogContentText>
          <TextField
            required
            autoFocus
            margin="dense"
            id="name"
            name="name"
            label="Team name"
            fullWidth
            variant="outlined"
            onChange={handleChange}
            value={formData?.name}
          />
          <DialogContentText className="py-2">Customizations</DialogContentText>
          <CustomizationPanel
            customizations={formData.customizations}
            setFormData={setFormData}
          />
        </DialogContent>
      )}
      <DialogActions className="p-4">
        <Button onClick={handleClose} disabled={creating || updating}>
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          loading={creating || updating}
          variant="contained"
        >
          {id ? "Update" : "Create"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateCampaign;
