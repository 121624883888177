import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Asynchronous from "./AutoComplete";
import { BlueprintService, JobService } from "../../../services";
import { AppConstants, NotificationConstants } from "../../../utils/constants";
import ActivityTable from "../activity/ActivityTable";
import { useNotify, usePut } from "../../../shared/hooks";
import { LoadingButton } from "@mui/lab";

const jobService = new JobService();
const { Completed } = AppConstants.PROJECT_STATUS;
const NOTIFICATION_TYPE = NotificationConstants.NOTIFICATION_TYPE;

const CreateBlueprint = ({ onClose, onBlueprintCreated }) => {
  const [notify] = useNotify();
  const [formData, setFormData] = useState();
  const [projects, setProjects] = useState([]);
  const { PutData, loading } = usePut();

  const getProjects = async () => {
    const resp = await jobService.getAllJobs(Completed, null, 1, 1000);
    setProjects(resp.records);
  };

  const onAutoselectChange = (name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target || {};
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleCreateBlueprint = async (e) => {
    e.preventDefault();
    const {
      project: { id },
      description,
      name,
    } = formData;
    const payload = new FormData();
    payload.set("bluePrint_name", name);
    payload.set("bluePrint_description", description);
    try {
      const resp = await PutData(
        new BlueprintService().createBlueprint(id),
        payload
      );
      onBlueprintCreated(resp.data);
      notify(true, resp.message, NOTIFICATION_TYPE.success, 5000);
      onClose();
    } catch (err) {
      notify(true, err.message, NOTIFICATION_TYPE.error, 5000);
    }
  };

  const customOptionRenderer = (props, option) => {
    const {
      job_name,
      job_type,
      fully_migrated,
      partially_migrated,
      not_migrated,
    } = option;
    return (
      <div {...props}>
        <div className="flex items-center justify-between gap-2">
          <span className="pr-2">{job_name || "-"}</span>
          <span className="bg-blue-100 px-2 rounded-sm">{job_type}</span>
          {fully_migrated != null && (
            <div className="flex gap-1">
              <span className="bg-[#00C49F] px-2 rounded-sm text-white">
                {fully_migrated}
              </span>
              <span className="bg-[#FFBB28] px-2 rounded-sm text-white">
                {partially_migrated}
              </span>
              <span className="bg-[#FF8042] px-2 rounded-sm text-white">
                {not_migrated}
              </span>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <Dialog
        open={true}
        fullWidth
        maxWidth="md"
        onClose={onClose}
        PaperProps={{
          component: "form",
          onSubmit: handleCreateBlueprint,
        }}
      >
        <DialogTitle>Create Blueprint</DialogTitle>

        <DialogContent>
          <DialogContentText className="pb-4">
            To create new blueprint, please select existing project and provide
            required details.
          </DialogContentText>
          <div className="flex gap-5">
            <TextField
              required
              autoFocus
              onChange={handleOnChange}
              margin="dense"
              id="name"
              name="name"
              label="Blueprint Name"
              fullWidth
              variant="outlined"
            />
            <Asynchronous
              options={projects}
              getRemoteData={getProjects}
              onChange={onAutoselectChange}
              autoFocus={false}
              required={true}
              fullWidth
              renderOption={customOptionRenderer}
              valueKey="job_name"
              label="Project Name"
              name="project"
            />
          </div>
          {formData?.project && (
            <div>
              <ActivityTable
                data={[formData.project]}
                hideActions={true}
                onRowViewClicked={() => null}
              />
            </div>
          )}
          <TextField
            required
            onChange={handleOnChange}
            multiline
            rows={4}
            margin="dense"
            id="description"
            name="description"
            label="Description"
            fullWidth
            variant="outlined"
          />
        </DialogContent>
        <DialogActions className="p-4">
          <Button onClick={onClose} disabled={loading}>
            Cancel
          </Button>
          <LoadingButton type="submit" loading={loading} variant="contained">
            Create
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default CreateBlueprint;
