import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { SHIP_DISCOVERY_UI_CONTROLLER } from "../../services/constant";
// import { useSelector } from "react-redux";

export const fetchAllMachineData = createAsyncThunk(
  "server/fetchAllMachineData",
  async (alerts, pageNumber) => {
    // const alerts = useSelector((state) => state.alertsData?.nodeDown);
    let {data}  = await axios.get(
      `${SHIP_DISCOVERY_UI_CONTROLLER}/servers_list`
    );
    return data.map((machine)=>(alerts.includes(`NodeDown~${machine?.ServerName}`)?{...machine, Status:'Stopped'}:machine));
  }
);


const getMachineDataSlice = createSlice({
  name: "machineData",
  initialState: { data: [], status: "idle", error: null, machines: [] },
  reducers: {
    setMachines: (state, action) => {
      state.data = state.data.map((machine)=>(action.payload.includes(`NodeDown~${machine?.ServerName}`)?{...machine, Status:'Stopped'}:machine));
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllMachineData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllMachineData.fulfilled, (state, action) => {
        

        state.status = "succeeded";
        state.data = action.payload;
        state.machines = action.payload.map((app) => app.ServerName);
      })
      .addCase(fetchAllMachineData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});
export const { setMachines } = getMachineDataSlice.actions;
export default getMachineDataSlice.reducer;
