import React from "react";

const ProjectHeader = ({ title, subtitle }) => {
  return (
    <div>
      <p className="text-xl font-semibold leading-4 mb-2 text-black">
        {title}
      </p>
      <p className="text-sm text-gray-600 mb-4">{subtitle}</p>
    </div>
  );
};

export default ProjectHeader;
