import React, { useState } from "react";
import FolderZipIcon from "@mui/icons-material/FolderZip";
import { Tooltip } from "@mui/material";
import { AppConstants } from "../../../utils/constants";
import ProjectHeader from "../../modecules/ProjectHeader";
import RadioButtonWithSubtitle from "../../atoms/radio";
import GreenfieldIcon from "../../../assests/greenfield.png";
import TibcoIcon from "../../../assests/tibco_logo.png";
const { INPUT_TYPE } = AppConstants;

const SourceSelection = ({ promptInput, setPromptInput, disabled }) => {
  const [isError, setIsError] = useState("");

  const { type, file, text } = promptInput || {};

  const handleTypeSelection = (value) => {
    if (disabled) {
      return;
    }
    setPromptInput((prev) => ({
      ...prev,
      type: value,
      isError: "",
      file: "",
      text: "",
    }));
  };

  const handleTextChange = (event) => {
    setPromptInput((prev) => ({ ...prev, text: event.target.value }));
    validatePromptText(event.target.value);
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setPromptInput((prev) => ({
      ...prev,
      file: selectedFile,
    }));
  };

  const validatePromptText = (value) => {
    if (value.trim() === "") {
      setIsError("Describe your project");
    } else {
      setIsError("");
    }
  };

  const radioOptions = [
    {
      title: "Upload a Tibco project",
      subtitle:
        "Choose this option if you have a source project ready to upload",
      value: INPUT_TYPE.shipZip,
      src: TibcoIcon,
    },
    {
      title: "Enter information about the project",
      subtitle:
        "Choose this option if you want to explain information about the flow within the project that you want to create. ",
      value: INPUT_TYPE.shipPrompt,
      src: GreenfieldIcon,
    },
    {
      title: "Migrate a project without using GenAI",
      subtitle:
        "Choose this option if you want to migrate a project without using GenAI. You will be able to upload a project file.",
      iconHoverText:
        "Source platforms supported are Tibco BW5, Tibco BW6 & WebMethods 10. Target platform can be Boomi.",
      value: INPUT_TYPE.shipTrad,
    },
    {
      title: "SHIP DISCOVERY",
      subtitle: "Disabled",
      iconHoverText: "This feature is coming soon",
      value: INPUT_TYPE.shipDiscovery,
      disabled: true,
    },
  ];

  return (
    <div className="bg-white shadow-md p-6 rounded-md">
      <ProjectHeader
        title="What is your current source?"
        subtitle="Let's begin by defining your source"
      />

      <div className="">
        {radioOptions.map((option) => (
          <RadioButtonWithSubtitle
            imageSrc={option.src}
            key={option.value}
            title={option.title}
            subtitle={option.subtitle}
            isChecked={type === option.value}
            onChange={() => handleTypeSelection(option.value)}
            disabled={option.disabled}
            loading={disabled && option.value === promptInput.type}
            iconHoverText={option.iconHoverText}
          >
            {type === option.value &&
              option.value === INPUT_TYPE.shipPrompt && (
                <>
                  <textarea
                    disabled={disabled}
                    className={`w-full p-2 border outline-secondary-300 ${
                      isError ? "border-red-500" : "border-gray-300"
                    } rounded-md`}
                    rows="4"
                    placeholder="Define about the project that you want to create in as much details as possible..."
                    value={text}
                    onChange={handleTextChange}
                  />
                  {isError && (
                    <p className="text-red-500 text-sm mt-1">{isError}</p>
                  )}
                </>
              )}
            {type === option.value &&
              [INPUT_TYPE.shipZip, INPUT_TYPE.shipTrad].includes(
                option.value
              ) && (
                <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-2">
                  <div className="text-center">
                    <div className="mt-2 text-sm leading-6 text-gray-600">
                      <label
                        for="file-upload"
                        className="relative cursor-pointer rounded-md font-semibold text-secondary-600 focus-within:outline-none hover:text-secondary-500"
                      >
                        <Tooltip title="Select a file" arrow>
                          <FolderZipIcon
                            style={{ fontSize: 40 }}
                            className="text-secondary-500"
                          />

                          <br />
                          {file && (
                            <p className="text-gray-700 font-normal">{file.name}</p>
                          )}
                          <span>Upload a zip file</span>
                          <input
                            id="file-upload"
                            name="file-upload"
                            type="file"
                            className="hidden"
                            onChange={handleFileChange}
                            accept=".zip,.rar,.7zip"
                          />
                        </Tooltip>
                      </label>
                    </div>
                    <p className="text-xs leading-5 text-gray-600">
                      Click the button to select a local file, or drag and drop
                      into the upload window
                    </p>
                  </div>
                </div>
              )}
          </RadioButtonWithSubtitle>
        ))}
      </div>
    </div>
  );
};

export default SourceSelection;
