import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { SHIP_DISCOVERY_UI_CONTROLLER } from "../../services/constant";

export const fetchDashBoardBarData = createAsyncThunk(
  "app/fetchAllDashBoardBarData",
  async (pageNumber) => {
    let {data}  = await axios.get(
      `${SHIP_DISCOVERY_UI_CONTROLLER}/dashboardBarData`
    );
    return data;
  }
);

const getDashboardBarDataSlice = createSlice({
  name: "dashboardBarData",
  initialState: { data: [], status: "idle", error: null, applications: [] },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDashBoardBarData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchDashBoardBarData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.dashboardBarData = action.payload.map((app) => app.ProductType);
      })
      .addCase(fetchDashBoardBarData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default getDashboardBarDataSlice.reducer;
