import React, { useState, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllDomainData } from "../../redux/getDomainDataSlice";
import { fetchAllApplicationData } from "../../redux/getApplicationDataSlice";
import { fetchAllMachineData } from "../../redux/getMachineDataSlice";
import MenuItem from "@mui/material/MenuItem";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField"; // Import TextField component from MUI
import Select from "@mui/material/Select"; // Import Select component from MUI
import FormControl from "@mui/material/FormControl"; // Import FormControl component from MUI
import SearchIcon from "@mui/icons-material/Search";
import { fetchAllBW6ApplicationData } from "../../redux/getBW6ApplicationListSlice";
import { fetchAllBW5ApplicationData } from "../../redux/getBW5ApplicationListSlice";
import MachineModal from "../../../components/Discovery/Machines/MachineModal";
import DomainModal from "../../../components/Discovery/Domains/DomainModal";
import { useNavigate } from "react-router-dom";
import { styled, lighten, darken } from '@mui/system';

const menus = [
  { label: "All", value: "All" },
  { label: "Domains", value: "domains" },
  { label: "Machines", value: "machines" },
  { label: "Applications", value: "applications" },
];

function SearchBar() {
  const dispatch = useDispatch();
  const domains = useSelector((state) => state.domainData.domains);
  const machines = useSelector((state) => state.machineData.machines);
  const bw6pplications = useSelector(
    (state) => state.bw6ApplistData.applications
  );
  const bw5pplications = useSelector(
    (state) => state.bw5ApplistData.applications
  );
  const machinedata = useSelector((state) => state.machineData?.data);
  const domainData = useSelector((state) => state.domainData?.data);
  const bw6AppData = useSelector(
    (state) => state.bw6ApplistData.data
  );
  const bw5AppData = useSelector(
    (state) => state.bw5ApplistData.data
  );
  //const status = useSelector((state) => state.domainData.status);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedMenu, setSelectedMenu] = useState("All");
  const [openMenu, setOpenMenu] = useState(false);
  const inputRef = useRef(null); // Ref for the Autocomplete input element
  const [selectedMachine, setSelectedMachine] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDomainModalOpen, setIsDomainModalOpen] = useState(false);
  const [selectedDomain, setSelectedDomain] = useState(null);
  const alerts = useSelector((state) => state.alertsData?.nodeDown);
  const navigate = useNavigate();
  useEffect(() => {
    // dispatch(fetchAllDomainData(alerts));
    // dispatch(fetchAllBW6ApplicationData());
    // dispatch(fetchAllBW5ApplicationData());
    // dispatch(fetchAllMachineData(alerts));
    console.log("refresh")

  }, [dispatch, alerts]);

  const handleSearchChange = (event, value) => {
    console.log(value)
    setSearchQuery(value);
    setOpenMenu(value !== ""); // Open the menu when there is text input
  };

  const handleMenuChange = (event) => {
    setSelectedMenu(event.target.value);
    setOpenMenu(false);
    // You can add logic here to load data based on the selected menu item
    //console.log(`Selected ${event.target.value}`);
  };

  const options = useMemo(() => {
    let tempDomains = domains?domains.map((domain)=>({title: 'Domain', value: domain})):[];
    let tempMachines = machines?machines.map((machines)=>({title: 'Machines', value: machines})):[];
    let tempBW5Apps = bw5pplications?bw5pplications.map((app)=>({title: 'BW5-Applications', value: app})):[];
    let tempBW6Apps = bw6pplications?bw6pplications.map((app)=>({title: 'BW6-Applications', value: app})):[];
    if (selectedMenu == "domains") {
      return tempDomains
    }
    if (selectedMenu === "machines") {
      return tempMachines
    }
    if (selectedMenu === "applications") {
      return [...tempBW6Apps, ...tempBW5Apps]
    }
    return [...tempDomains, ...tempMachines, ...tempBW6Apps, ...tempBW5Apps];
  }, [selectedMenu, domains, machines, bw6pplications, bw5pplications]);
  const selectedValues = React.useMemo(
    () => options.filter((v) => v.selected),
    [options],
  );
  const handleOptionsClicked=(e, value)=>{
    console.log(e.target.value, value)
    if(domains.includes(value)){
      console.log("domain")
      let selectedDomain = domainData.find(
        (obj) => obj.DomainName === value
      );
      setSelectedDomain(selectedDomain)
      setIsDomainModalOpen(true)
    }else if(bw6pplications.includes(value)){
      let selectedApp = bw6AppData.find(
        (obj) => obj.ApplicationName === value
      );
      navigate(`/d/bw6applications/${selectedApp?.ApplicationId}`)
    }else if(bw5pplications.includes(value)){
      let selectedApp = bw5AppData.find(
        (obj) => obj.ApplicationName === value
      );
      navigate(`/d/bw5applications/${selectedApp?.ApplicationId}`)
    }else if(machines.includes(value)){
      console.log("machine")
      let selectedMachine = machinedata.find(
        (obj) => obj.ServerName === value
      );
      setSelectedMachine(selectedMachine);
      setIsModalOpen(true);
    }
    setOpenMenu(false);
  }
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsDomainModalOpen(false)
  };

const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === 'light'
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled('ul')({
  padding: 0,
});
  return (
    <div className="flex items-center justify">
      <div>
        <Autocomplete
          options={options}
          open={openMenu}
          //={status === 'loading'}
          onChange={handleOptionsClicked}
          className=""
          renderGroup={(params) => (
            <li key={params.key}>
              <GroupHeader>{params.group}</GroupHeader>
              <GroupItems>{params.children}</GroupItems>
            </li>
          )}
          groupBy={(option) => option.title}
          getOptionLabel={(option) => option.value}
          renderInput={(params) => (
            <TextField
            variant="outlined"
              {...params}
              ref={inputRef}
              type="text"
              placeholder="Search..."
              value={searchQuery}
              className="h-8 w-80 bg-white outline-none border-none hover:outline-none hover:border-none rounded-s-md"
              onChange={(event) =>
                handleSearchChange(event, event.target.value)
              }
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <SearchIcon
                    sx={{ color: '#0a669b', marginLeft: "8px", border: "white" }}
                    className="h-5 w-5"
                  />
                ),
                disableunderline: true,
                className:"p-0 h-8",
                //inputProps: { style: { padding: 0 } },

                endAdornment: null, // Hides the menu icon
              }}
            />
          )}
        />
      </div>
      <div>
        {/* <FormControl>
          <Select
            value={selectedMenu}
            onChange={handleMenuChange}
            className="h-9 text-slate-400 border-none outline-none bg-white"
          >
            {menus.map(({ label, value }) => (
              <MenuItem value={value}>{label}</MenuItem>
            ))}
          </Select>
        </FormControl> */}
        <select value={selectedMenu}
            onChange={handleMenuChange} className="h-8 outline-none bg-secondary text-white rounded-e-md border-l border-slate-400">
          {menus.map(({ label, value }) => (
              <option style={{padding: '50px'}} value={value}>{label}</option>
            ))}
        </select>
      </div>
      <MachineModal
        open={isModalOpen}
        onClose={handleCloseModal}
        data={selectedMachine}
      />
      <DomainModal
        open={isDomainModalOpen}
        onClose={handleCloseModal}
        data={selectedDomain}
      />
    </div>
  );
}

export default SearchBar;
