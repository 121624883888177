class BlueprintService {
  constructor() {
    this.BASE_URL =
      "https://ship-ai-queue.ambitiousgrass-4f9c5e31.centralus.azurecontainerapps.io";
  }

  getBlueprints = (page = 1, limit = 10) =>
    `${this.BASE_URL}/blueprints?page=${page}&limit=${limit}`;

  createBlueprint = (projectId) => `${this.BASE_URL}/blueprint/${projectId}`;
}

export default BlueprintService;
