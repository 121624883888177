import React from "react";
import "./assests/css/app.css";
// import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AuthProvider from "./shared/context/AuthContext";
import store from "./shared/redux/store";
import { Provider } from "react-redux";
import Notification from "./shared/components/organisms/notification";
import Ai from "./shared/routing/AiRouteprovider";
import Discovery from "./shared/routing/DiscoveryRouteprovider";

export default function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <AuthProvider>
          <Routes>
            <Route path="/*" element={<Ai />} />
            <Route path="d/*" element={<Discovery />} />
          </Routes>
          <Notification />
        </AuthProvider>
      </BrowserRouter>
    </Provider>
  );
}
