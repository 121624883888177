import ListItem from "../atoms/ListItem";

const ListItems = ({ data, columns, nullValue = "-", cols = 2 }) => {
  return (
    <div className={`grid grid-cols-${cols} gap-x-10 gap-y-2`}>
      {columns?.map(({ label, key }) => {
        return <ListItem label={label} value={data[key] ?? nullValue} />;
      })}
    </div>
  );
};

export default ListItems;
