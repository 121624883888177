export const domainColumns = [
  { label: "Domain Name", key: "DomainName" },
  { label: "Application #", key: "applicationCount" },
  { label: "Database URL", key: "DatabaseUrl" },
  { label: "Machine #", key: "serverCount" },
  { label: "Product Type", key: "ProductType" },
  {
    label: "Monitoring Mgmt Enabled",
    key: "MonitoringManagementEnabled",
  },
  { label: "Transport URL", key: "TransportUrl" },
  { label: "Transport Type", key: "TransportType" },
  { label: "Status", key: "Status" },
];
