export const machineColumns = [
  { label: "IP Address", key: "IpAddress" },
  { label: "OS", key: "OperatingSystem" },
  { label: "CPU Count", key: "Cpus" },
  { label: "Cores / CPU", key: "Cores" },
  { label: "File System(s)", key: "fileSystem" },
  { label: "Total Memory", key: "MemoryTotal" },
  { label: "Used Memory", key: "MemoryUsed" },
  { label: "Free Memory", key: "MemoryFree" },
  { label: "Total DiskSpace", key: "DiskSpaceTotal" },
  { label: "Used DiskSpace", key: "DiskSpaceUsed" },
  { label: "Free DiskSpace", key: "DiskSpaceFree" },
  { label: "Uptime", key: "Uptime" },
  { label: "Software Name", key: "description" },
  { label: "Status", key: "Status" },
];
