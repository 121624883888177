import { configureStore, combineReducers } from "@reduxjs/toolkit";
import notificationReducer from '../components/organisms/notification/notificationSlice';
import domainDataReducer from './getDomainDataSlice';
import machineDataReducer from './getMachineDataSlice';
import applicationReducer from './getApplicationDataSlice';
import alertReducer from './getAlertsDataSlice';
import envReducer from './getEnvironmentListSlice'
import BW5appDetailReducer from './getBW5AppDetailsDataSlice'
import BW6appDetailReducer from './getBW6AppDetailsDataSlice'
import sidebarReducer from './getSidebarDataSlice';
import dashboardBarDataReducer from './getDashboardBarDataSlice';
import bw5ApplistDataReducer from './getBW5ApplicationListSlice';
import bw6ApplistDataReducer from './getBW6ApplicationListSlice';
import appSpacesReducer from './getAppSpacesListSlice';
import appNodesReducer from './getAppNodesListSlice'


// Define action type
const SET_SIDEBAR_VISIBLE = 'navigation/SET_SIDEBAR_VISIBLE';

// Action creator
export const setSidebarVisible = (isVisible) => ({
  type: SET_SIDEBAR_VISIBLE,
  payload: isVisible,
});

// Initial state
const initialNavigationState = {
  isSidebarVisible: true, // Initially visible
};

// Navigation reducer
const navigationReducer = (state = initialNavigationState, action) => {
  switch (action.type) {
    case SET_SIDEBAR_VISIBLE:
      return {
        ...state,
        isSidebarVisible: action.payload,
      };
    default:
      return state;
  }
};

// Combine reducers
const rootReducer = combineReducers({
  navigation: navigationReducer,
  notification: notificationReducer,
  domainData: domainDataReducer,
  machineData: machineDataReducer,
  applicationsData: applicationReducer,
  alertsData:alertReducer,
  environmentData:envReducer,
  BW6appDetailData: BW6appDetailReducer,
  BW5appDetailData: BW5appDetailReducer,
  sidebarData:sidebarReducer,
  dashboardBarData: dashboardBarDataReducer,
  bw5ApplistData: bw5ApplistDataReducer,
  bw6ApplistData: bw6ApplistDataReducer,
  appSpaces: appSpacesReducer,
  appNodes: appNodesReducer,

});

// Configure store
const store = configureStore({
  reducer: rootReducer,
});

export default store;
