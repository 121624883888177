import { Button, Tooltip } from "@mui/material";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import RefreshIcon from "@mui/icons-material/Refresh";

const DownloadButton = ({
  downloading,
  onDownload,
  btnText = "ZIP",
  tooltipText = "Download project",
  variant = "text",
  size = "small",
}) => {
  return (
    <Tooltip title={tooltipText} arrow>
      <Button
        variant={variant}
        size={size}
        disabled={downloading}
        startIcon={
          downloading ? (
            <RefreshIcon className="animate-spin" />
          ) : (
            <CloudDownloadOutlinedIcon />
          )
        }
        // className="text-gray-600"
        onClick={onDownload}
      >
        {btnText}
      </Button>
    </Tooltip>
  );
};

export default DownloadButton;
