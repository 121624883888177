import React, { useCallback } from "react";
import BasicCard from '../atoms/BasicCard'
import AlertItem from '../atoms/AlertItem'
import { Link } from 'react-router-dom'
import { fetchAllAlertData } from "../../../shared/redux/getAlertsDataSlice";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";

const AlertCard = () => {

    const dispatch = useDispatch();
    const data = useSelector((state) => state.alertsData?.data);
    const fetchData = useCallback(() => {
        dispatch(fetchAllAlertData(0));
      }, []);
      
    useEffect(() => {
        fetchData();
    }, [fetchData]);
    
    const alertData = [{
        name: 'Network Failure',
    },
    {
        name: 'Max Heap Size Reached',
    },
    {
        name: 'Max Heap Size Reached',
    },
]
  return (
    <div className='h-48'>
     <BasicCard backgroundColor='bg-blue-300' height={'h-64'}> 
     <div className=' flex justify-between text-xl mb-4 text-black'>Alerts <span> <Link  to={'/d/alerts'} className=' text-secondaryDark text-xs self-end  font-medium rounded-md p-2'>View All</Link></span></div>
     <div className='overflow-y-auto'>
     { data.map((d, i) => (
            <AlertItem key={i} item={d} />
        ))  }
     </div>
     {/* <div className='flex justify-end items-end w-full'>
           
     </div> */}
    </BasicCard>
    </div>
   
  )
}

export default AlertCard