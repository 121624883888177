import React from 'react';
import { Button, IconButton, Tooltip } from '@mui/material';
import AppUtils from '../../../utils/AppUtils';

import RefreshIcon from '@mui/icons-material/Refresh';
import { AppConstants } from '../../../utils/constants';
import DownloadButton from './activityDetails/DownloadButton';

const appUtils = AppUtils;

const { Completed } = AppConstants.PROJECT_STATUS;

const ActivityDetailHeader = ({
  jobDetail,
  refreshProject,
  refreshing,
  onOutputDownload,
  onInputDownload,
  outputDownloading,
  inputDownloading,
}) => {
  const { end_time, start_time, job_status } = jobDetail || {};

  const renderStatus = () => {
    if (!jobDetail) return null;
    return (
      <p
        className={`text-sm px-2 py-1 rounded-sm ${appUtils.getStatusClass(
          job_status
        )}`}
      >
        <span className="font-medium">Status: </span>
        {job_status}
      </p>
    );
  };

  const renderTimeInfo = () => {
    return (
      <p className="text-sm text-gray-600 mr-4">
        <span className="font-medium text-gray-500">Started: </span>
        {start_time ? appUtils.getTime(start_time) : 'N/A'} |{' '}
        <span className="font-medium text-gray-500">Ended: </span>
        {end_time ? appUtils.getTime(end_time) : 'N/A'}
      </p>
    );
  };

  return (
    <>
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          <h2 className=" text-4xl font-bold mr-4  capitalize">
            {jobDetail?.job_name || 'Job Name'}
          </h2>
          <span className="text-sm bg-blue-200 text-secondary-800 px-2 py-1 rounded-sm">
            {jobDetail?.job_type}
          </span>
        </div>
        {renderStatus()}
      </div>
      {jobDetail?.job_type === 'Greenfield' && (
        <div className="flex items-center justify-between">
          <p className="text-slate-400">{jobDetail?.input_content}</p>
        </div>
      )}

      <div className="flex items-center justify-between mt-4">
        <div className="flex items-center">
          {' '}
          {/* Align input and output sections */}
          <p className="text-sm text-gray-600 mr-4">
            <span className="font-medium text-gray-500">Input: </span>
            {jobDetail?.job_type === 'Greenfield' ? (
              'Prompt'
            ) : (
              <DownloadButton
                onDownload={onInputDownload}
                downloading={inputDownloading}
              />
            )}
          </p>
        </div>
        {renderTimeInfo()}
      </div>
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <p className="text-sm text-gray-600 mr-4">
            <span className="font-medium text-gray-500">Output: </span>
            {job_status === Completed ? (
              <>
                <DownloadButton
                  onDownload={onOutputDownload}
                  downloading={outputDownloading}
                />
              </>
            ) : (
              'N/A'
            )}
          </p>
        </div>
        {job_status !== Completed && (
          <Tooltip title={refreshing ? 'Refreshing' : 'Refresh'}>
            <IconButton onClick={refreshProject} disabled={refreshing}>
              <RefreshIcon className={refreshing ? 'animate-spin' : ''} />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </>
  );
};

export default ActivityDetailHeader;
