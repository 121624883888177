import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { SHIP_DISCOVERY_UI_CONTROLLER } from "../../services/constant";

export const fetchAllEnvironmentData = createAsyncThunk(
  "environments/fetchAllEnvironmentData",
  async () => {
    let { data } = await axios.get(`${SHIP_DISCOVERY_UI_CONTROLLER}/environments`);
    return data;
  }
);

const getEnvironmentDataSlice = createSlice({
  name: "environmentData",
  initialState: { data: [], status: "idle", error: null, environments: [] },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllEnvironmentData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllEnvironmentData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.environments = action.payload.map((env) => ({
          label: env?.EnvironmentName,
        }));
      })
      .addCase(fetchAllEnvironmentData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default getEnvironmentDataSlice.reducer;
