import { AppConstants } from "../utils/constants";
import axiosInstance from "./AxiosService";

class JobService {
  constructor() {
    this.BASE_URL =
      "https://ship-ai-queue.ambitiousgrass-4f9c5e31.centralus.azurecontainerapps.io";
  }

  // create a function to get all jobs
  async getAllJobs(filter, campaignId, page = 1, limit = 15) {
    let url = `${this.BASE_URL}/jobs?limit=${limit}&page=${page}`;
    if (![undefined, "All"].includes(filter)) {
      url += `&status=${filter}`;
    }
    if (campaignId) {
      url += `&project=${campaignId}`;
    }
    const response = await axiosInstance.get(url);
    return response.data;
  }

  getJobsUrl = (page = 1, limit = 5) =>
    `${this.BASE_URL}/jobs?limit=${limit}&page=${page}&status=Completed`;

  getJobStatsUrl = (id) => `${this.BASE_URL}/jobStats/${id}`;

  getjobsOverviewUrl = (status) =>
    `${this.BASE_URL}/jobsOverview?status=${status}`;
  //create a function to get all stage of a job
  async getJobById(jobId) {
    try {
      const response = await axiosInstance.get(`${this.BASE_URL}/job/${jobId}`);
      return response.data;
    } catch (error) {
      console.error(`Error fetching job with ID ${jobId}:`, error);
      return null;
    }
  }
  //create a function to get a job by id
  async getAllStagesOfJob(jobId) {
    const response = await axiosInstance.get(
      `${this.BASE_URL}/job/${jobId}/stages`
    );
    return response.data;
  }

  //create a function to get all stage of a job
  //create a job with preview
  async createJobWithPreview(input, projectInput) {
    const {
      campaign: { id, user_id, customizations },
      customizations: updatedCustomizations,
      projectName,
    } = projectInput;
    const updatedStr = JSON.stringify(updatedCustomizations);
    const isUpdated = updatedStr === JSON.stringify(customizations);

    const formData = new FormData();
    if (AppConstants.INPUT_TYPE.shipPrompt === input.type) {
      formData.append(
        "prompt",
        JSON.stringify({
          prompt: input.text,
        })
      );
    } else {
      formData.append("input_file", input.file);
    }
    if (!isUpdated) {
      formData.append("customization", updatedStr);
    }
    formData.append("preview", true);
    formData.append("project_id", id);
    formData.append("user_id", user_id);
    formData.append("job_name", projectName);

    const response = await axiosInstance.put(`${this.BASE_URL}/job`, formData);
    return response.data;
  }
  //create a function to manulaly start a job by id
  async manuallyStartJobById(jobId) {
    try {
      // Logic to manually start the job by sending a request to the API
    } catch (error) {
      console.error(`Error manually starting job with ID ${jobId}:`, error);
    }
  }
  //create a function to resume a job by id
  async resumeJobById(jobId) {
    const response = await axiosInstance.put(
      `${this.BASE_URL}/job/${jobId}/next_stage`
    );
    return response.data;
    // Logic to resume the job by sending a request to the API
  }

  // create a function to start a specific stage of a job
  async startSpecificStageOfJob(jobId, stageId) {
    const response = await axiosInstance.put(
      `${this.BASE_URL}/job/${jobId}/stage/${stageId}/start`
    );
    return response.data;
  }
  //create a function to get a sepcific stage info of a job by jobid, and stageid
  async getSpecificStageInfoOfJob(jobId, stageId) {
    try {
      const response = await axiosInstance.get(
        `${this.BASE_URL}/job/${jobId}/stage/${stageId}`
      );
      return response.data;
    } catch (error) {
      console.error(
        `Error fetching stage ${stageId} info of job with ID ${jobId}:`,
        error
      );
      return null;
    }
  }
  async getStageOutputsOfJob(jobId, stageId) {
    const response = await axiosInstance.get(
      `${this.BASE_URL}/job/${jobId}/stage/${stageId}/output`
    );
    return response;
  }

  async getJobOutput(jobId) {
    const response = await axiosInstance.get(
      `${this.BASE_URL}/job/${jobId}/output`,
      {
        responseType: "blob",
      }
    );
    var fileName = "output.zip";
    const contentDisposition = response.headers.get("content-disposition");
    if (contentDisposition && contentDisposition.includes("filename=")) {
      fileName = contentDisposition
        .split("filename=")[1]
        ?.replace(/^"|"$/g, "");
    }

    return { data: response.data, fileName };
  }
  async getJobInput(jobId) {
    const response = await axiosInstance.get(
      `${this.BASE_URL}/job/${jobId}/input`,
      {
        responseType: "blob",
      }
    );

    var fileName = "input.zip";
    const contentDisposition = response.headers["content-disposition"];
    if (contentDisposition && contentDisposition.includes("filename=")) {
      fileName = contentDisposition
        .split("filename=")[1]
        ?.replace(/^"|"$/g, "");
    }

    return { data: response.data, fileName };
  }
}
export default JobService;
