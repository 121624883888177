import { AppBar, IconButton, Toolbar, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ToggleButtonGroup from "../atoms/ToggleButtonGroup";
import { useState } from "react";
import { AppConstants } from "../../utils/constants";
import CodeIcon from "@mui/icons-material/Code";
import SchemaIcon from "@mui/icons-material/Schema";
import Flow from "./Flow";
import ReactJson from "react-json-view";

const { BLOCKS, CODE } = AppConstants;
const VIEWS = [
  { value: BLOCKS, icon: SchemaIcon },
  { value: CODE, icon: CodeIcon },
];

const FlowDisplay = ({ toggleModal, title, steps, showExpandIcon, json }) => {
  const [activeView, setActiveView] = useState(BLOCKS);

  const handleChangeView = (newView) => {
    if (newView != null || activeView !== newView) {
      setActiveView(newView);
    }
  };

  return (
    <>
      <AppBar component="nav">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={toggleModal}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flexGrow: 1 }} variant="h6" component="div">
            {title}
          </Typography>

          <ToggleButtonGroup
            value={activeView}
            onChange={handleChangeView}
            items={VIEWS}
          />
        </Toolbar>
      </AppBar>
      <div className="mt-16 p-2 h-full">
        {activeView === BLOCKS && (
          <Flow
            data={steps}
            toggleModal={toggleModal}
            showExpandIcon={showExpandIcon}
          />
        )}
        {activeView === CODE && (
          <ReactJson src={json} enableClipboard={false} />
        )}
      </div>
    </>
  );
};

export default FlowDisplay;
