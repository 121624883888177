import React, { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu"; // Importing the Menu icon from Material UI
import SearchIcon from "@mui/icons-material/Search";

// Reusable Dropdown Component
const Dropdown = ({ name, value, onChange, options, placeholder }) => (
  <select
    name={name}
    value={value}
    onChange={onChange}
    className="py-1 h-10 w-40 border indent-3 text-gray-400 border-blue-300 rounded-lg"
  >
    <option value="">{placeholder}</option>
    {[...options].map(
      (
        option // Convert Set to array and then map
      ) => (
        <option key={option} value={option}>
          {option}
        </option>
      )
    )}
  </select>
);

const Filters = ({
  filters,
  onFilterChange,
  dropdownValues,
  onFilterReset,
}) => {
  const renderFilter = (item, index) => {
    const { type, key, placeholder, value } = item || {};
    if (type == "DROPDOWN")
      return (
        <Dropdown
          name={key}
          value={value}
          onChange={(e) => onFilterChange(e.target.value, index)}
          options={dropdownValues[key] || []}
          placeholder={placeholder}
        />
      );
    if (type == "TEXT")
      return (
        <div className="relative">
          <input
            type="search"
            name={key}
            value={value}
            onChange={(e) => onFilterChange(e.target.value, index)}
            placeholder={placeholder}
            className="px-8 py-1 pr-8 h-10 border border-blue-300 rounded-lg"
          />
          <div className="absolute left-1 top-1/2 transform -translate-y-1/2 text-gray-400">
            <SearchIcon />
          </div>
        </div>
      );
  };
  return (
    <form className="flex items-end gap-4  justify-between">
      <div />
      <div className="flex gap-2">
        {filters.map(renderFilter)}
        <button
          type="button"
          onClick={onFilterReset}
          className="px-5 h-10 text-xs bg-secondary text-white rounded-lg"
        >
          Clear All
        </button>
      </div>
    </form>
  );
};

export default Filters;
