import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";

import Applications from "../../components/Discovery/Bw5Applications";
import Domains from "../../components/Discovery/Domains";
import Machines from "../../components/Discovery/Machines";
import Dsidebar from "../components/Discovery/dsidebar";
import Header from "../components/Discovery/Header";
import Ddashboard from "../../components/Discovery/Ddashboard/ddashboard";
import Alerts from "../../components/Discovery/Alerts";
import Bw5AppDetailes from "../../components/Discovery/Bw5AppDetails/bw5appdetails";
import Bw6Appdetails from "../../components/Discovery/Bw6AppDetails/bw6pdetails";
import Bw5Applications from "../../components/Discovery/Bw5Applications";
import Bw6Applications from "../../components/Discovery/Bw6Applications";
import AppNodes from "../../components/Discovery/AppNodes";
import AppSpaces from "../../components/Discovery/AppSpaces";

const routes = [
  
  { path: "/", component: Ddashboard },
  { path: "/domains", component: Domains },
  { path: "/bw5applications", component: Bw5Applications },
  { path: "/bw6applications", component: Bw6Applications },
  { path: "/appspaces", component: AppSpaces },
  { path: "/appnodes", component: AppNodes },
  { path: "/Machines", component: Machines },
  { path: "/bw6applications/:appId", component: Bw6Appdetails },
  { path: "/bw5applications/:appId", component: Bw5AppDetailes },
  { path: "/alerts", component: Alerts },
];

const Discovery= () => {
  const location = useLocation();
  const NotFound = () => {
    return (
      <div>
        <h2>404 Not Found</h2>
      </div>
    );
  };

 

  return (
    <div className="flex flex-col h-screen">
      <Header />
      <div className="flex flex-grow h-screen">
       <Dsidebar />

        <div className="flex-grow h-full min-h-screen sm:px-8 md:px-8 mt-4 bg-slate-100">
          <Routes>
            {routes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={<route.component />}
                exact={route.exact}
              />
            ))}
            <Route path="*" element={<NotFound />} />{" "}
             </Routes>
        </div>
      </div>
    </div>
  );
};

export default Discovery;
