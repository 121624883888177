import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import InsightsIcon from "@mui/icons-material/Insights";
import FolderSpecialIcon from "@mui/icons-material/FolderSpecial";
import ModelTrainingIcon from "@mui/icons-material/ModelTraining";
import { IconButton, Tooltip } from "@mui/material";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

const SIDEBAR_ITEMS = [
  { text: "Dashboard", icon: SpaceDashboardIcon, route: "/" },
  { text: "Projects", icon: InsightsIcon, route: "/projects" },
  { text: "Teams", icon: FolderSpecialIcon, route: "/campaigns" },
  { text: "Blueprints", icon: ModelTrainingIcon, route: "/blueprints" },
];

function Sidebar() {
  const location = useLocation();
  const { pathname } = location;
  const [activeItem, setActiveItem] = useState("");
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(true);

  useEffect(() => {
    const activeSidebar = pathname.split("/")?.[1] ?? "/";

    const matchedItem = SIDEBAR_ITEMS.find((item) =>
      item.route.includes(activeSidebar)
    );
    setActiveItem(matchedItem ? matchedItem.text : "");
  }, []);

  const handleItemClick = (text) => {
    setActiveItem(text);
  };

  const toggleSidebar = () => {
    setIsSidebarCollapsed((prevState) => !prevState);
  };

  if (pathname === "/login") return;

  return (
    <div
      className={`h-full text-white font-poppins ${
        isSidebarCollapsed ? "w-16" : "w-60"
      } tinker md:bg-black/[0.15] dark:bg-transparent relative py-5 px-5 md:py-0 sm:px-8 md:px-0 after:bg-gradient-to-b after:from-theme-1 after:to-theme-2 dark:after:from-darkmode-800 dark:after:to-darkmode-800 after:fixed after:inset-0 after:z-[-2]`}
    >
      {/* Sidebar Content */}
      <div className="py-6">
        <ul className="mt-1">
          {SIDEBAR_ITEMS.map((item, index) => (
            <Link to={item.route} key={index}>
              <li
                className={`flex items-center py-3 px-4 hover:bg-secondary-200 ${
                  activeItem === item.text
                    ? "bg-[#f4f7f8] text-primary font-medium"
                    : ""
                }`}
                onClick={() => handleItemClick(item.text)}
              >
                <Tooltip title={item.text} placement="right" arrow>
                  <item.icon className="h-6 w-6 mr-2 text-secondary-700" />
                </Tooltip>
                {!isSidebarCollapsed && <span>{item.text}</span>}
              </li>
            </Link>
          ))}
        </ul>
      </div>
      {/* Footer */}
      <div className="py-4 px-2">
        <IconButton onClick={toggleSidebar}>
          {isSidebarCollapsed ? (
            <KeyboardDoubleArrowRightIcon />
          ) : (
            <KeyboardDoubleArrowLeftIcon />
          )}
        </IconButton>
      </div>
    </div>
  );
}

export default Sidebar;
