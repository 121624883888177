import React, { useState, useEffect, useRef } from "react";
import { useLocation, Navigate } from "react-router-dom"; // Import Navigate for programmatic navigation
import SearchBar from "./dsearchbar";
import ReplayCircleFilledOutlinedIcon from '@mui/icons-material/ReplayCircleFilledOutlined';
import CircularSpinner from "../../../components/atoms/spinner";
import { Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllEnvironmentData } from "../../redux/getEnvironmentListSlice";
import {getRefreshStatus, refreshData} from "../../../api/RefreshApi"
import { useNotify } from "../../hooks";
import { NotificationConstants } from "../../../utils/constants";
import { grey } from "@mui/material/colors";
import { fetchDashBoardBarData } from "../../redux/getDashboardBarDataSlice";
import { fetchSidebarData } from "../../redux/getSidebarDataSlice";
import { fetchAllDomainData } from "../../redux/getDomainDataSlice";
import { fetchAllMachineData } from "../../redux/getMachineDataSlice";
import { fetchAllBW5ApplicationData } from "../../redux/getBW5ApplicationListSlice";
import { fetchAllBW6ApplicationData } from "../../redux/getBW6ApplicationListSlice";

const NOTIFICATION_TYPE = NotificationConstants.NOTIFICATION_TYPE;
function Header1() {
  const [notify] = useNotify();
  const location = useLocation();
  const { pathname } = location;
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const intervalId = useRef(null);
  const dropdownRef = useRef(null);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const dispatch = useDispatch();
  const environments = useSelector((state) => state?.environmentData?.environments);
  
  
  const dropdownItems = [
    { label: "Dashboard", link: "#" },
    { label: "Profile", link: "#" },
    { label: "Settings", link: "#" },
    { label: "Logout", link: "#" },
  ];

  // const environments = [
  //   { label: "DEV", route: "/dev" },
  //   { label: "PROD", route: "/prod" },
  //   { label: "PERF", route: "/perf" },
  // ];

  const [activeEnv, setActiveEnv] = useState('DEV');


  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  const handleItemClick = () => {
    setIsDropdownOpen(false);
  };

  const fetchRefreshStatus = async () => {
    try {
      const resp = await getRefreshStatus();
    if(resp?.data?.status==='Completed'){
      setIsRefreshing(false);
      if(intervalId?.current){
        clearInterval(intervalId.current);
      }
      notify(true, "Refreshed successfully!", NOTIFICATION_TYPE.success);
      dispatch(fetchDashBoardBarData());
      dispatch(fetchSidebarData());
      dispatch(fetchAllDomainData());
      dispatch(fetchAllMachineData());
      dispatch(fetchAllBW5ApplicationData());
      dispatch(fetchAllBW6ApplicationData());
      // TODO refresh all data
    }else if(resp?.data?.status==='NoPendingRequests'){
      setIsRefreshing(false);
      if(intervalId?.current){
        clearInterval(intervalId.current);
      }
    }
    } catch (error) {
      // setIsRefreshing(false);
      // if(intervalId?.current){
      //   clearInterval(intervalId.current);
      // }
    }
  };

  const handleRefreshClick = async () => {
    if(!isRefreshing){
    setIsRefreshing(true);
    notify(true, "Refresh Is In Progress", NOTIFICATION_TYPE.info);
      await refreshData().then(()=>{
        try {
          const tempIntervalId = setInterval(() => {
            fetchRefreshStatus();
          }, 60000); // 300000ms = 5 minutes
          intervalId.current = tempIntervalId
        } catch (error) {
          console.error(error)
          notify(true, "Failed To Refresh", NOTIFICATION_TYPE.error, 5000);
        }
      }).catch(()=>{
        setIsRefreshing(false);
        notify(true, "Failed To Refresh", NOTIFICATION_TYPE.error, 5000);
      });
    
  }
  };

  useEffect(() => {
    dispatch(fetchAllEnvironmentData());
    return (()=>{
      if(intervalId?.current){
        clearInterval(intervalId.current);
      }
    })
  }, [dispatch]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (pathname === "/login") return <Navigate to="/" />; // Redirect to home if path is "/login"

  return (
    <header className="bg-primary py-3 px-6 flex justify-between items-center shadow-md w-full sticky">
      <div className="flex items-center">
        <h1 className="text-xl text-white font-semibold text-nowrap">SAGE-IT INC </h1>
        <div className="flex-grow border-l border-primaryDark border-1 h-10 mx-4"></div> {/* Vertical line */}
        {environments && environments.map((env, index) => (
          <div
            key={index}
            className= {`mx-4 lg:text-md md:text-sm sm:text-sm ${activeEnv === env.label ? 'text-popOne' : 'text-white hover:text-popOne'} font-semibold cursor-pointer`}
            onClick={() => setActiveEnv(env.label)} // Use Navigate to handle navigation
          >
            {env.label}
          </div>
        ))}
         <Tooltip title={'Refresh Active Environment'} placement="top" arrow><ReplayCircleFilledOutlinedIcon height="30px" className={`mr-2 ${isRefreshing? 'text-gray-300':'text-white'}`} onClick={() => handleRefreshClick()} /> </Tooltip>
        <div className="mx-40">
          <SearchBar />
        </div>
      </div>

      <div className="flex items-center justify-center"> 
        <button
          className="bg-white border border-secondary-600 rounded-full p-1 h-8 w-8 text-primaryDark text-sm font-bold text-center"
          onClick={toggleDropdown}
        >
          R
        </button>

        {/* Dropdown Menu */}
        <div className="ml-4 relative z-50" ref={dropdownRef}>
          {/* Dropdown Content */}
          <div
            className={`absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg border-0 ${
              isDropdownOpen ? "" : "hidden"
            }`}
          >
            <div className="py-1">
              {dropdownItems.map((item, index) => (
                <a
                  key={index}
                  href={item.link}
                  className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                  onClick={handleItemClick}
                >
                  {item.label}
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header1;
