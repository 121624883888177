export const BASE_URL = "http://localhost:8080";
export const SHIP_AI_CODE_GENERATOR_URL =
  "https://ship-ai-code-generator.ambitiousgrass-4f9c5e31.centralus.azurecontainerapps.io/generate-code";
export const SHIP_AI_DECONSTRUCT_URL =
  "https://ship-ai-deconstruct.ambitiousgrass-4f9c5e31.centralus.azurecontainerapps.io/extract_project";

export const SHIP_AI_ORCHESTRATOR_URL =
  "https://ship-ai-orchestrator.ambitiousgrass-4f9c5e31.centralus.azurecontainerapps.io/generate-mule-prompt";

export const SHIP_DISCOVERY_UI_CONTROLLER = 
"https://ui-controller.ambitiousgrass-4f9c5e31.centralus.azurecontainerapps.io"

