import React, { useMemo } from "react";
import BasicFlow from "../../../flow";
import Modal from "../../../atoms/modal";
import { useNotify } from "../../../../shared/hooks";
import {
  AppConstants,
  NotificationConstants,
} from "../../../../utils/constants";
import { formatApiPayloadToFlow } from "../../../../utils";
import { getLayoutedElements } from "../../../flow/utils";

const { NOTIFICATION_TYPE } = NotificationConstants;
const { MESSAGE } = AppConstants;

const StageInputOutput = ({
  index,
  handleClose,
  jobId,
  jobStages,
  type,
  fullScreen = true,
}) => {
  const [notify] = useNotify();
  const currentStageDetails = jobStages[index] || {};
  const {
    stage_input,
    input_content,
    stage_output,
    output_content,
    stage_name,
  } = currentStageDetails;

  const content = type === "INPUT" ? input_content : output_content;
  const stageInfo = type === "INPUT" ? stage_input : stage_output;

  const steps = useMemo(() => {
    if (
      !currentStageDetails ||
      stageInfo?.type !== "JSON" ||
      (index === 0 && type === "INPUT")
    ) {
      return;
    }

    let json;
    try {
      json = JSON.parse(content);
    } catch (e) {
      notify(
        true,
        "An error occurred while processing the output. It's possible that the output doesn't exist.",
        NOTIFICATION_TYPE.error,
        5000
      );
    }

    if (typeof json === "string") {
      notify(true, MESSAGE.notEnoughInputs, NOTIFICATION_TYPE.error, 5000);
      return;
    }

    if (!json) return;

    const { nodes, edges } = formatApiPayloadToFlow(json);
    return getLayoutedElements(nodes, edges);
  }, [currentStageDetails]);

  if (index === 0 && type === "INPUT") {
    return (
      <Modal isOpen={true} onClose={handleClose}>
        {/* Displaying modal content based on input type */}
        {stageInfo.type === "FILE" && (
          <div className="text-center">
            Download your input project
            <p>{stageInfo.name}</p>
          </div>
        )}
        {stageInfo.type === "JSON" && (
          <>{JSON.parse(content).prompt || JSON.parse(content).prompts}</>
        )}
      </Modal>
    );
  }

  return (
    <>
      {stageInfo?.type === "JSON" && (
        <>
          <BasicFlow
            steps={steps}
            json={JSON.parse(content)}
            fullScreen={fullScreen}
            onCollapsed={handleClose}
            title={stage_name}
            showExpandIcon={false}
          />
        </>
      )}
    </>
  );
};

export default StageInputOutput;
