export const appSpaceColumns = [
    { label: "Min Nodes", key: "MinNodes" },
    { label: "Status", key: "AppSpaceStatus" },
    { label: "Nodes Count", key: "NodesCount" },
    { label: "Applications Count", key: "ApplicationsCount" },
    { label: "Description", key: "AppSpaceDescription" },
    {
      label: "Min Heap Size",
      key: "AppSpaceMinHeapSize",
    },
    { label: "Max Heap Size", key: "AppSpaceMaxHeapSize" },
    // { label: "Machine", key: "ServerName" },
  ];
  
export const appNodesColumns = [
  { label: "Management Port", key: "ManagementPort" },
    { label: "Status", key: "AppNodeStatus" },
    { label: "Agent", key: "Agent" },
    { label: "Config State", key: "ConfigState" },
    { label: "Up Time", key: "UpTime" },
    {
      label: "Default Min Heap Size",
      key: "DfltMinHeapSize",
    },
    { label: "Default Max Heap Size", key: "DfltMaxHeapSize" },
    { label: "Min Heap Size", key: "AppNodeMinHeapSize" },
    { label: "Max Heap Size", key: "AppNodeMaxHeapSize" },
    { label: "Machine", key: "ServerName" },
]