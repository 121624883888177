import React from 'react'

const AlertItem = ({item}) => {
  return (
    <div className='flex shadow-md p-2 mb-2 rounded-md bg-white'>
        <div className='bg-secondaryLight w-1 mr-2'>
        </div>
        <p className='text-black flex-grow'>{item.AlertName}</p>
    </div>
  )
}

export default AlertItem