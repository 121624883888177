import { createSlice } from "@reduxjs/toolkit";

const notificationSlice = createSlice({
    name: 'notification',
    initialState: {
        open: false,
        durration: 0,
        message: '',
        severity: 'info'
    },
    reducers: {
        showNotification: (state, action) => {
            state.open = true;
            state.durration = action.payload.durration;
            state.message = action.payload.message;
            state.severity = action.payload.severity;
        },
        hideNotificaiton: (state) => {
            state.open = false;
        },
    },
});

export const {showNotification, hideNotificaiton} = notificationSlice.actions;
export default notificationSlice.reducer;