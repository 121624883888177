import axios from "axios";
import { SHIP_DISCOVERY_UI_CONTROLLER } from "../services/constant";

export const refreshData= async ()=> {
    return await axios.get(
    `${SHIP_DISCOVERY_UI_CONTROLLER}/refresh`
    )
}

export const getRefreshStatus= async ()=> {
    return await axios.get(
    `${SHIP_DISCOVERY_UI_CONTROLLER}/refresh_status`
    )
}