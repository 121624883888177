import React from "react";
import CircularSpinner from "./spinner";

const Overlay = () => {
  return (
    <>
      <div className="fixed inset-0 z-50 flex items-center justify-center overflow-auto bg-opacity-40">
        <div className="relative w-full max-w-lg p-8 mx-auto rounded-lg">
          <div className="p-4">
            <CircularSpinner />
          </div>
        </div>
      </div>
    </>
  );
};

export default Overlay;
