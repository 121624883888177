import React, { useState, useEffect, useRef } from "react";
// import Logo from "../../../assests/logo.png";
import Logo from "../../assests/logo.png";
import sagelogo from "../../assests/Sage-IT-Logo-SVG.svg";
import { useLocation, useNavigate } from "react-router-dom";
import AuthService from "../../services/AuthService";
const authService = new AuthService();
function Header() {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const dropdownItems = [
    { label: "Dashboard", link: "#" },
    { label: "Profile", link: "#" },
    { label: "Settings", link: "#" },
    { label: "Logout", link: "#" },
  ];

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  const handleItemClick = (label) => {
    if (label === "Logout") {
      authService.logout();
      const { pathname } = window.location;
      if (pathname === "/") {
        navigate(`/login`);
        return;
      }
      navigate(`/login?next=${pathname}`);
    }
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (pathname === "/login") return;
  return (
    <header className="bg-white py-4 px-6 flex justify-between items-center shadow-md">
      <div className="flex items-center">
        <img src={sagelogo} alt="Logo" className="h-6" />

        {/* <h1 className="text-xl text-primary font-semibold">.AI</h1> */}
      </div>
      <img src={Logo} alt="SageIT Logo" className="h-12" />
      <div className="flex items-center">
        <button
          className="bg-primary text-white border border-secondary-600 rounded-full p-1 h-10 w-10"
          onClick={toggleDropdown}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill-rule="evenodd"
            stroke-linejoin="round"
            stroke-miterlimit="2"
            clip-rule="evenodd"
            viewBox="0 0 64 64"
            id="user"
          >
            <g>
              <rect width="64" height="64" fill="none"></rect>
              <circle
                cx="259"
                cy="30"
                r="5"
                fill="#3fc1c9"
                transform="matrix(3 0 0 3 -745 -67)"
              ></circle>
              <path
                fill="#fff"
                d="M259,24.333C255.872,24.333 253.333,26.872 253.333,30C253.333,33.128 255.872,35.667 259,35.667C262.128,35.667 264.667,33.128 264.667,30C264.667,26.872 262.128,24.333 259,24.333ZM259,25.667C261.392,25.667 263.333,27.608 263.333,30C263.333,32.392 261.392,34.333 259,34.333C256.608,34.333 254.667,32.392 254.667,30C254.667,27.608 256.608,25.667 259,25.667Z"
                transform="matrix(3 0 0 3 -745 -67)"
              ></path>
              <path
                fill="#fff"
                d="M258.577,33.514C256.252,34.745 254.667,37.189 254.667,40C254.667,40.368 254.368,40.667 254,40.667C253.632,40.667 253.333,40.368 253.333,40C253.333,36.505 255.406,33.49 258.389,32.12C258.63,32.01 258.914,32.052 259.112,32.229C259.879,32.916 260.891,33.333 262,33.333C263.109,33.333 264.121,32.916 264.888,32.229C265.086,32.052 265.37,32.01 265.611,32.12C268.594,33.49 270.667,36.505 270.667,40C270.667,40.368 270.368,40.667 270,40.667C269.632,40.667 269.333,40.368 269.333,40C269.333,37.189 267.748,34.745 265.423,33.514C264.473,34.237 263.286,34.667 262,34.667C260.714,34.667 259.527,34.237 258.577,33.514Z"
                transform="matrix(3 0 0 3 -754 -64)"
              ></path>
            </g>
          </svg>
        </button>

        {/* Dropdown Menu */}
        <div className="ml-4 relative z-50" ref={dropdownRef}>
          {/* Dropdown Content */}
          <div
            className={`absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg border  ${
              isDropdownOpen ? "" : "hidden"
            }`}
          >
            <div className="py-1">
              {dropdownItems.map((item, index) => (
                <a
                  key={index}
                  // href={item.link}
                  className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                  onClick={() => handleItemClick(item.label)}
                >
                  {item.label}
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
