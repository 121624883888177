// AppContext.js

import React, { createContext, useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AuthService from "../../services/AuthService";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();

  const checkTokenAndGetUser = async () => {
    const authService = new AuthService();
    if (!authService.isAuthenticated()) navigate("/login");
  };

  useEffect(() => {
    checkTokenAndGetUser();
  }, []);

  return <AuthContext.Provider value={{}}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
export const useAuthContext = () => useContext(AuthContext);
