import { useEffect, useState } from "react";
import { useParams } from "react-router";
import CircularSpinner from "../../../atoms/spinner";
import ActivityDetailHeader from "../ActivityDetailHeader";
import { NotificationConstants } from "../../../../utils/constants";
import ActivityDetailSkeleton, {
  StepperSkeleton,
} from "../../../modecules/skelton";
import { JobService } from "../../../../services";
import { useGet, useNotify } from "../../../../shared/hooks";
// import Card from './Card';
import StageCard from "./StageCard";
import SummaryCard from "./SummaryCard";
import StageInputOutput from "./StageInputOutput";
import AppUtils from "../../../../utils/AppUtils";
import ActivitiesTypesCount from "./tables/ActivitiesTypeCount";
import MetricsCard from "./MetricsCard";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import DeveloperBoardIcon from "@mui/icons-material/DeveloperBoard";
import MemoryIcon from "@mui/icons-material/Memory";
import ActivitiesAndFlows from "./ActivitiesAndFlows";

const NOTIFICATION_TYPE = NotificationConstants.NOTIFICATION_TYPE;

const JobDetail = () => {
  const jobService = new JobService();
  const { fetchData, data, loading: loadingStats, error } = useGet();
  const [notify] = useNotify();
  const { jobId } = useParams();
  const [jobDetail, setJobDetail] = useState();
  const [loading, setLoading] = useState(false);
  const [jobStages, setJobStages] = useState([]);
  const [currentStepDigrams, setCurrentStepDigrams] = useState(0);
  const [isInputOutputVisible, setIsInputOutputVisible] = useState(false);
  const [activeBtnType, setActiveBtnType] = useState();
  const [resumeIndex, setResumeIndex] = useState(-1);
  const [resumeLoading, setResumeLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [inputDownloading, setInputDownloading] = useState(false);
  const [outputDownloading, setOutputDownloading] = useState(false);

  const getJobDetail = async () => {
    const resp = await jobService.getJobById(jobId);
    setJobDetail(resp);
  };

  const findResumeIndex = async (stages = []) => {
    let index = -1;
    stages?.some((stage, idx) => {
      const status = stage?.stage_status;
      if (status && ["In progress", "In Progress", "Failed"].includes(status)) {
        return true;
      }
      if (!status) {
        index = idx;
        return true;
      }
      return false;
    });
    setResumeIndex(index);
  };

  const getJobStages = async () => {
    try {
      const resp = await jobService.getAllStagesOfJob(jobId);
      setJobStages(resp);
      setLoading(false);
      findResumeIndex(resp);
      return resp;
    } catch (err) {
      notify(
        true,
        "Something went wrong while fetching stage info, " + err.message,
        NOTIFICATION_TYPE.error,
        5000
      );
    }
  };

  const pollingService = async () => {
    let resp = await getJobStages();
    const inProgress = resp.some((stg) =>
      ["In Progress", "In progress"].includes(stg.stage_status)
    );
    if (inProgress && !isInputOutputVisible) {
      setTimeout(pollingService, 5000);
    }
  };

  const getJobStatastics = async () => {
    try {
      await fetchData(jobService.getJobStatsUrl(jobId));
    } catch (err) {}
  };

  useEffect(() => {
    setLoading(true);
    getJobDetail();
    getJobStages();
    getJobStatastics();
  }, []);

  const handleToggleInputOutput = () => {
    setIsInputOutputVisible((pre) => !pre);
  };

  const handleJobReTrigger = async (step) => {
    setLoading(true);
    try {
      const resp = await jobService.startSpecificStageOfJob(jobId, step);
      getJobStages();
    } catch (err) {
      notify(true, err.message, NOTIFICATION_TYPE.error, 5000);
    } finally {
      setLoading(false);
    }
  };

  const refreshProject = async () => {
    setRefreshing(true);
    await getJobDetail();
    await getJobStages();
    setRefreshing(false);
    notify(true, "Project Refreshed", NOTIFICATION_TYPE.info, 5000);
  };

  const handleChangeBlockDiagram = (index) => {
    setCurrentStepDigrams(index);
  };

  const handleResume = async () => {
    setResumeLoading(true);
    try {
      await jobService.resumeJobById(jobId);
      await getJobStages();
    } catch (err) {
      notify(true, err.message, NOTIFICATION_TYPE.error, 5000);
    } finally {
      setResumeLoading(false);
    }
  };

  const onProjectInputDownload = async () => {
    setInputDownloading(true);
    try {
      const { data, fileName } = await jobService.getJobInput(jobId);
      AppUtils.downloadFile(data, fileName);
      notify(
        true,
        "Source downloaded successfully!",
        NOTIFICATION_TYPE.success,
        5000
      );
    } catch (e) {
      notify(
        true,
        "Failed to download the project. Please try again later.",
        NOTIFICATION_TYPE.error,
        5000
      );
      console.error(e);
    } finally {
      setInputDownloading(false);
    }
  };

  const onProjectOutputDownload = async () => {
    setOutputDownloading(true);
    try {
      const { data, fileName } = await jobService.getJobOutput(jobId);
      AppUtils.downloadFile(data, fileName);
      notify(
        true,
        "Project downloaded successfully!",
        NOTIFICATION_TYPE.success,
        5000
      );
    } catch (e) {
      notify(
        true,
        "Failed to download the project. Please try again later.",
        NOTIFICATION_TYPE.error,
        5000
      );
      console.error(e);
    } finally {
      setOutputDownloading(false);
    }
  };

  if (loading) {
    <CircularSpinner />;
  }
  const CardItems = [
    {
      label: "Flows",
      key: "total_flow_count",
      bg: "bg-theme-1/20 text-theme-1 border-theme-1/55",
      icon: <AccountTreeIcon className="scale-100" />,
    },
    {
      label: "Total Components",
      key: "total_components",
      bg: "bg-theme-2/20 text-theme-2 border-theme-2/55",
      icon: <MemoryIcon className="scale-100" />,
    },
    {
      label: "Fully Migrated",
      key: "fully_migrated",
      bg: "bg-danger/10 text-danger/40 border-danger/35",
      icon: <DeveloperBoardIcon className="scale-100" />,
    },
    {
      label: "Group Activities",
      key: "total_group_activities_count",
      bg: "bg-gradient-to-tr from-[#2ef43b] to-[#3b7541]",
    },
    {
      label: "Http Activity",
      key: "total_SIM_found",
      bg: "bg-white text-secondary",
    },
    {
      label: "Total Activities",
      key: "total_activities_count",
      bg: "bg-white text-secondary",
    },
    {
      label: "Global Components",
      key: "total_global_components_count",
      bg: "bg-white text-secondary",
    },

    {
      label: "JMS Activity",
      key: "total_SIM_found",
      bg: "bg-white text-secondary",
    },

    {
      label: "Partially Migrated",
      key: "partially_migrated",
      bg: "bg-gradient-to-tr from-[#ffa800] to-[#ffa800]",
    },
    {
      label: "Not Migrated",
      key: "not_migrated",
      bg: "bg-gradient-to-tr from-[#ffa800] to-[#ffa800]",
    },

    {
      label: "SIMs",
      key: "total_SIM_found",
      bg: "bg-gradient-to-tr from-[#ffa800] to-[#ffa800]",
    },
    {
      label: "Code Found",
      key: "total_code_found",
      bg: "bg-gradient-to-tr from-[#2ef43b] to-[#3b7541]",
    },

    {
      label: "Total Functions",
      key: "total_function_found",
      bg: "bg-gradient-to-tr from-[#2ef43b] to-[#3b7541]",
    },

    {
      label: "Package found",
      key: "total_package_found",
      bg: "bg-gradient-to-tr from-[#2ef43b] to-[#3b7541]",
    },
  ];

  const handleOutputClick = (index) => {
    setActiveBtnType("OUTPUT");
    handleChangeBlockDiagram(index);
    handleToggleInputOutput();
  };

  const handleInputClick = (index) => {
    setActiveBtnType("INPUT");
    handleChangeBlockDiagram(index);
    handleToggleInputOutput();
  };
  return (
    <div>
      <div className="mt-4 bg-white shadow-md p-4 m-6 rounded-md">
        <ActivityDetailHeader
          jobDetail={jobDetail}
          refreshProject={refreshProject}
          refreshing={refreshing}
          onOutputDownload={onProjectOutputDownload}
          inputDownloading={inputDownloading}
          outputDownloading={outputDownloading}
          onInputDownload={onProjectInputDownload}
        />
      </div>
      <div className="mt-4 px-6 ">
        {/* <SummaryCard
          label="Summary"
          items={CardItems.slice(5)}
          jobDetail={jobDetail}
        /> */}
        {/* <div className="py-4"> */}
        {loading ? (
          <StepperSkeleton />
        ) : (
          <ol
            role="list"
            className="acw adb aet afz agy cty cwq bg-white border-0 shadow-lg"
          >
            {jobStages?.map((stage, index) => (
              <StageCard
                stageCount={jobStages?.length || 0}
                key={index}
                stage={stage}
                onOutputClick={() => handleOutputClick(index)}
                onInputClick={() => handleInputClick(index)}
                onResume={handleResume}
                isResume={index === resumeIndex}
                resumeLoading={resumeLoading}
                onOutputDownload={onProjectOutputDownload}
                inputDownloading={inputDownloading}
                outputDownloading={outputDownloading}
                onInputDownload={onProjectInputDownload}
                currentStageNumber={index}
              >
                {!isInputOutputVisible && (
                  <StageInputOutput
                    type={jobStages.length - 1 === index ? "INPUT" : "OUTPUT"}
                    index={index}
                    handleClose={handleToggleInputOutput}
                    jobId={jobId}
                    jobStages={jobStages}
                    fullScreen={false}
                  />
                )}
              </StageCard>
            ))}
          </ol>
        )}
      </div>

      {!loading && !loadingStats && (
        <div className=" mt-2 px-6 grid grid-cols-1 gap-0 mb-28">
          <div className="mt-4 text-2xl text-primary">Overview</div>
          <div className="text-md text-slate-500">
            Integration process insights and activity metrics at a glance,
            highlighting different components involved and external system
            interactions.
          </div>
          <div className="rounded-lg p-2">
            <div>
              <div class="rounded-lg bg-white shadow-md p-4">
                <div class="box-body">
                  <div class="flex flex-wrap mb-0 list-none justify-around text-center gap-2">
                    {CardItems.slice(0, 3).map((item, index,) => (
                      <MetricsCard
                        icon={item.icon}
                        key={index}
                        data={jobDetail?.[item.key]}
                        title={item.label}
                        bg={item.bg}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ActivitiesAndFlows data={data} />

          {/* <ActivitiesTypesCount
            data={data}
            loading={loadingStats}
            error={!!error}
          /> */}

          {/* </div> */}
          {isInputOutputVisible && (
            <StageInputOutput
              type={activeBtnType}
              index={currentStepDigrams}
              handleClose={handleToggleInputOutput}
              jobId={jobId}
              jobStages={jobStages}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default JobDetail;
