import { useMemo } from "react";

import { formatApiPayloadToFlow } from "../../../utils";
import ProjectHeader from "../../modecules/ProjectHeader";
import { getLayoutedElements } from "../../flow/utils";
import BasicFlow from "../../flow";

// import { AppConstants } from "../../utils/constants";
// import CodeIcon from "@mui/icons-material/Code";
// import SchemaIcon from "@mui/icons-material/Schema";
// import ToggleButtonGroup from "../atoms/ToggleButtonGroup";

// const { BLOCKS, CODE } = AppConstants;
// const VIEWS = [
//   { value: BLOCKS, icon: SchemaIcon },
//   { value: CODE, icon: CodeIcon },
// ];

const ProjectVisualizer = ({
  stepOneOutput,
  setStepOneOutput,
  disabled = false,
}) => {
  // const [activeView, setActiveView] = useState(BLOCKS);

  const steps = useMemo(() => {
    const { nodes, edges } = formatApiPayloadToFlow(stepOneOutput);
    return getLayoutedElements(nodes, edges);
  }, [stepOneOutput]);

  // const handleChange = (newView) => {
  //   if (newView !== null || activeView !== newView) {
  //     setActiveView(newView);
  //   }
  // };

  // const displayText = useMemo(
  //   () => JSON.stringify(stepOneOutput, null, 4),
  //   [stepOneOutput]
  // );

  return (
    <div className="bg-white shadow-md p-6 rounded-md">
      <div className="flex justify-between ">
        <div>
          <ProjectHeader
            title="Components of Your Project"
            subtitle="Begin creating a new project within this workspace"
          />
        </div>
        {/* <div>
          <ToggleButtonGroup
            value={activeView}
            onChange={handleChange}
            items={VIEWS}
          />
        </div> */}
      </div>
      <div className="mt-2 border rounded-md">
        <div style={{ height: "calc(100vh - 350px)" }}>
          <BasicFlow
            steps={steps}
            json={stepOneOutput}
            showExpandIcon={false}
          />
        </div>

        {/* {activeView === CODE && (
          <textarea
            className="w-full h-full px-3 py-2  rounded-md text-sm focus:outline-none focus:border-primary-400"
            value={displayText}
            disabled={disabled}
            rows={20}
            onChange={(e) => {
              setStepOneOutput(e.target.value);
            }}
          />
        )} */}
      </div>
    </div>
  );
};

export default ProjectVisualizer;
