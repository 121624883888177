import { useState } from "react";
import axiosInstance from "../../services/AxiosService";

const usePost = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const postData = async (url, payload) => {
    setLoading(true);
    try {
      const resp = await axiosInstance.post(url, payload);
      setData(resp.data);
      return resp.data;
    } catch (err) {
      setError(err);
      throw err;
    } finally {
      setLoading(false);
    }
  };
  return { postData, loading, data, error };
};

export default usePost;
