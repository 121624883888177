import { Step, Stepper } from "@mui/material";
import React from "react";

const ActivityDetailSkeleton = () => {
  return (
    <>
      <div className="flex justify-between items-center mb-8">
        <div className="flex items-center">
          <div className="animate-pulse bg-gray-100 w-24 h-6 rounded-md mr-4"></div>

          <div className="animate-pulse bg-gray-100 w-20 h-6 rounded-md"></div>
        </div>
        <div className="animate-pulse bg-gray-100 w-20 h-6 rounded-md"></div>
      </div>
      <div className="flex items-center justify-between">
        <p className="text-sm text-gray-600 mr-4">
          <span className="animate-pulse bg-gray-100 w-20 h-4 rounded-md inline-block mr-2"></span>
          <span className="animate-pulse bg-gray-100 w-20 h-4 rounded-md inline-block"></span>
        </p>
      </div>
    </>
  );
};

export default ActivityDetailSkeleton;

export const StepperSkeleton = () => {
  const MAX_COUNT = 4;
  return (
    <Stepper
      orientation="vertical" // Set the orientation to vertical
      className="mb-12"
      connector={null} // Remove the connector line
    >
      <div className="h-8 ml-10 w-96 mb-2 bg-gray-50 rounded-sm animate-pulse" />
      {Array(MAX_COUNT)
        .fill()
        .map((_, index) => (
          <Step key={index}>
            <div className="flex items-center">
              <div className="h-8 w-8 bg-gray-100 rounded-full mr-2 animate-pulse" />
              <div className="flex-1">
                <div className="h-1 w-full rounded-sm mb-6 animate-pulse" />{" "}
                {/* Add a vertical line separator */}
                <div className="h-30 w-full p-4 bg-gray-50 rounded-sm animate-pulse">
                  {" "}
                  <div className="h-4 w-full mb-2 bg-gray-100 rounded-sm animate-pulse" />
                  <div className="h-4 w-full mb-2 bg-gray-100 rounded-sm animate-pulse" />
                </div>
              </div>
            </div>
          </Step>
        ))}
    </Stepper>
  );
};
