import React, { useCallback, useEffect, useState } from "react";
import Filters from "../Filters/filters";
import machinerawdata from "../Machines/machinerawdata"; // Import machines data
import Table from "../../../shared/components/organisms/table/Table";
import { fetchAllApplicationData } from "../../../shared/redux/getApplicationDataSlice";
import { useDispatch, useSelector } from "react-redux";
import DashboardBar from "../../../shared/components/organisms/dahboardBar/DashboardBar";
import { applyFilters } from "../Filters/helpers";

const customClasses = {
  table: "flex-row-reverse w-full overflow-y rounded-md",
  thead: "bg-secondary text-left h-12 uppercase text-white sticky top-0 z-10",
  th: "px-2",
  tr: "justify-between flex-grow mb-2",
  td: "max-w-60 min-w-8 truncate text-ellipsis text-left text-sm py-2 px-4 align-middle border-b border-secondary-100",
};

const initialFilters = [
  {
    type: "TEXT",
    key: "applicationName",
    placeholder: "Name",
    value: "",
  },
  {
    type: "DROPDOWN",
    key: "software",
    placeholder: "software",
    value: "",
  },
  {
    type: "DROPDOWN",
    key: "state",
    placeholder: "state",
    value: "",
  },
  {
    type: "DROPDOWN",
    key: "domain",
    placeholder: "domain",
    value: "",
  },
];

const Dashboard = () => {
  const dispatch = useDispatch();

  const data = useSelector((state) => state.applicationsData?.data);
  const [rowData, setRowData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [filters, setFilters] = useState(initialFilters);
  const [dropdownValues, setDropdownValues] = useState({});

  useEffect(() => {
    setRowData(data);
    const software = new Set();
    const state = new Set();
    const machineName = new Set();
    const domain = new Set();
    data?.forEach((item) => {
      software.add(item.software);
      state.add(item.state);
      machineName.add(item.machineName);
      domain.add(item.domain);
    });
    setDropdownValues({ software, state, machineName, domain });
  }, [data]);

  useEffect(() => {
    const filteredData = applyFilters(filters, data);
    setRowData(filteredData);
  }, [filters]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Application Name",
        accessor: "ApplicationName",
      },
      { Header: "Description", accessor: "Description" },
      { Header: "Version", accessor: "Version" },
      {
        Header: "Status",
        accessor: "Status",
        Cell: ({ row }) => (
          <div
            className={`rounded-md w-16 p-1 text-center text-white ${
              row.original.Status === "Running"
                ? "bg-green-500"
                : "bg-red-500"
            }`}
          >
            {row.original.Status ? row.original.Status : "Not Running"}
          </div>
        ),
      },
      {
        Header: "Domain",
        accessor: "DomainName",
      }
    ],
    []
  );

  const handleFilterChange = (value, index) => {
    const cloned = JSON.parse(JSON.stringify(filters));
    const obj = cloned[index];
    obj.value = value;
    setFilters(cloned);
  };

  const onFilterReset = () => {
    setFilters(initialFilters);
  };

  const fetchData = useCallback((pageIndex) => {
    dispatch(fetchAllApplicationData(pageIndex));
    setPageCount(pageCount + 1);
  }, []);

  return (
    <div className="h-screen flex flex-col">
      <DashboardBar />
      <div className="flex-grow">
        <Filters
          filters={filters}
          onFilterChange={handleFilterChange}
          onFilterReset={onFilterReset}
          dropdownValues={dropdownValues}
        />

        <div className="mt-4 overflow-x-auto">
          <Table
            columns={columns}
            data={rowData}
            fetchData={fetchData}
            pageCount={pageCount}
            customClasses={customClasses}
          />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
