export const serviceInstanceColumns = [
    { label: "Thread Count", key: "ThreadCount" },
    { label: "Max Log file Count", key: "MaxLogfileCount" },
    { label: "Initial Heap Size", key: "InitialHeapSize" },
    { label: "Thread Stack Size", key: "ThreadStackSize" },
    { label: "Append Class Path", key: "AppendClassPath" },
    {
      label: "Prepend Class Path",
      key: "PrependClassPath",
    },
    { label: "Home Directory", key: "HomeDirectory" },
  ];
  